import { useState } from "react";
import { NavLink } from "react-router-dom";

function SumuriAdminSidebar() {
  const [selected, setSelected] = useState(1);

  return (
    <div className="w-60 h-full shadow-md bg-[#24272d] px-1 fixed">
      <ul className="relative">
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/"
            className={`flex items-center text-sm py-4 px-6 h-12 ${
              selected == 1 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out `}
            onClick={() => setSelected(1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-3">Grant License</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/newLicense"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 3 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(3)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-3">Create License Type</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/disableLicense"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 4 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(4)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-3">Disable License</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/hardware"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 5 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(5)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-3">Allowed Hardwares</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/editIssued"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 6 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(6)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            <span className="ml-3">Edit Issued Licenses</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/userAdministration"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 7 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(7)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              className="ml-1 flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="#6B7280"
            >
              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
            </svg>
            <span className="ml-3">User Administration</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/orgAdministration"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 8 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(8)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 576 512"
              className="ml-1 flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="#6B7280"
            >
              <path d="M208 80c0-26.5 21.5-48 48-48h64c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48h-8v40H464c30.9 0 56 25.1 56 56v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H464c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-4.4-3.6-8-8-8H312v40h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H256c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V280H112c-4.4 0-8 3.6-8 8v32h8c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V368c0-26.5 21.5-48 48-48h8V288c0-30.9 25.1-56 56-56H264V192h-8c-26.5 0-48-21.5-48-48V80z" />
            </svg>

            <span className="ml-3">Org. Administration</span>
          </NavLink>
        </li>
        <li className="relative">
          <NavLink
            to="/sumuriDashboard/profile"
            className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${
              selected == 9 ? "text-gray-900 bg-gray-100" : "text-white"
            } text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`}
            onClick={() => setSelected(9)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              className="ml-1 flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="#6B7280"
            >
              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
            </svg>
            <span className="ml-3">Profile</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
export default SumuriAdminSidebar;
