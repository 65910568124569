import React, { Dispatch, SetStateAction } from "react";

import { Organization, OrganizationLicenses } from "../../common/interfaces";
import { DateConverter } from "../../common/util/DateConverter";

interface Props {
  setShow: Dispatch<SetStateAction<boolean>>;
  org: Organization;
  setCurrentLicense: Dispatch<SetStateAction<OrganizationLicenses | null>>;
}

export const ShowLicenses = ({ setShow, org, setCurrentLicense }: Props) => {
  const handleChangeCount = (license: OrganizationLicenses) => {
    setShow(true);
    setCurrentLicense(license);
  };

  return (
    <div className="flex">
      <table className="table-auto mt-4 ">
        <thead>
          <tr className="bg-[#ffce00]">
            <th className="border-black border-4 border-t-0 py-2 px-4">License Name</th>
            <th className="border-black border-4 border-t-0 py-2 px-4">Duration</th>
            <th className="border-black border-4 border-t-0 py-2 px-4">Start Date</th>
            <th className="border-black border-4 border-t-0 py-2 px-4">End Date</th>
            <th className="border-black border-4 border-t-0 py-2 px-4">License count</th>
          </tr>
        </thead>

        <tbody className="bg-white">
          {org.organizationLicenses.map((license: OrganizationLicenses) => (
            <tr className="border-b-4 border-[#BEBEBE] text-center">
              <td className="py-3">{license.licenseType.name}</td>
              <td className="py-3">{license.licenseType.duration}</td>
              <td className="py-3">{DateConverter(license.purchaseDate)}</td>
              <td className="py-3">{DateConverter(license.expiryDate)}</td>
              <td className="py-3">
                <div className="flex justify-center items-center">
                  <span className="mr-2">{license.quantity}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleChangeCount(license)}
                    height="1em"
                    viewBox="0 0 512 512"
                    className="cursor-pointer"
                  >
                    <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
