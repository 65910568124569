import { Dispatch, SetStateAction } from "react";

import { Organization, UsersOrganization } from "../../common/interfaces";
import { ViewMembers } from "./ViewMembers";

interface ShowMembersProps {
  orgMembers: UsersOrganization[];
  setOrgMembers: Dispatch<SetStateAction<UsersOrganization[]>>;
  unsetAdminNotAllowed: boolean;
  setModalInvitationEmail: Dispatch<SetStateAction<boolean>>;
  setCurrentUser: Dispatch<SetStateAction<UsersOrganization | undefined>>;
  setModalRemoveUser: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => Promise<void>;
  loadingInvitation: boolean;
  handleGoBackShowMembers: () => void;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
}
export const ShowMembers = ({
  orgMembers,
  setOrgMembers,
  unsetAdminNotAllowed,
  setModalInvitationEmail,
  setCurrentUser,
  setModalRemoveUser,
  setMessage,
  handleFetchAllOrganizations,
  loadingInvitation,
  handleGoBackShowMembers,
  setOrgSelected,
}: ShowMembersProps) => {
  return (
    <>
      <div className="text-center w-3/5">
        <ViewMembers
          orgMembers={orgMembers}
          setOrgMembers={setOrgMembers}
          unsetAdminNotAllowed={unsetAdminNotAllowed}
          setModalInvitationEmail={setModalInvitationEmail}
          setCurrentUser={setCurrentUser}
          setModalRemoveUser={setModalRemoveUser}
          setMessage={setMessage}
          handleFetchAllOrganizations={handleFetchAllOrganizations}
          loadingInvitation={loadingInvitation}
          setOrgSelected={setOrgSelected}
        />

        <button
          onClick={handleGoBackShowMembers}
          className="py-2 px-4 mt-4 border border-transparent bg-[#cca500] text-sm font-medium rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1"
        >
          Back
        </button>
      </div>
    </>
  );
};
