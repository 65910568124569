import { invitedUser } from "../../Organizations";

interface invitedUsersTableProps {
    invitedUsers: invitedUser[]
}

function InvitedUsersTable(props :invitedUsersTableProps) {
    const {invitedUsers} = props
    return (
        <div style={{ "textAlign": "center" }}>
            <h2 className="mt-16 text-center text-3xl font-sans text-white mb-6">Invited Users to Organization</h2>
                <div className="flex justify-center">
                    { invitedUsers.length ? 
                    <table className="table-auto mt-4 w-4/5">
                        <thead>
                            <tr className="bg-[#ffce00]">
                                <th className="border-black border-b-4 py-2">Name</th>
                                <th className="border-black border-4 border-t-0 py-2">Email</th>
                                <th className="border-black border-4 border-t-0 py-2">Invited By</th>
                                <th className="border-black border-b-4 py-2">Invitation Date</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {invitedUsers.map((invitedUser, i) => {
                                return (
                                    <tr key={i} className="border-b-4 border-[#BEBEBE]">
                                        <td className="py-3"> {invitedUser.name}</td>
                                        <td> {invitedUser.email} </td>
                                        <td> {invitedUser.invitingUserName} </td>
                                        <td> {new Date(invitedUser.createdAt).toLocaleDateString("en-US")} </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    :
                    <h2 className="mt-6 text-center text-3xl font-sans text-white">Selected organization has no users</h2>
                    }
                </div>        </div>
    );
}

export default InvitedUsersTable;