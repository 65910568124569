import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { OrganizationContext } from "../../../context/OrganizationContext";
import { Organization, UsersOrganization } from "../../common/interfaces";

import { ApiClient } from "../../common/util/ApiClient";

interface Props {
  userOrg: UsersOrganization;
  unsetAdminNotAllowed: boolean;
  setOrgMembers: Dispatch<SetStateAction<UsersOrganization[]>>;
  orgMembers: UsersOrganization[];
  setCurrentUser: Dispatch<SetStateAction<UsersOrganization | undefined>>;
  setModalRemoveUser: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => void;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
}

export const ViewMemberRow = ({
  userOrg,
  unsetAdminNotAllowed,
  setOrgMembers,
  orgMembers,
  setCurrentUser,
  setModalRemoveUser,
  setLoading,
  setMessage,
  handleFetchAllOrganizations,
  setOrgSelected,
}: Props) => {
  const {
    orgState: { allOrganizations },
  } = useContext(OrganizationContext);
  const { put } = ApiClient();

  const [isAdmin, setIsAdmin] = useState(userOrg.isAdmin);

  const handleChangeAdmin = async () => {
    if (unsetAdminNotAllowed && isAdmin) {
      return;
    }

    const message = {
      successMessage: "",
      errorMessage: "",
    };
    setLoading(true);
    try {
      await put(`organizations/user/${userOrg.id}`, { isAdmin: !isAdmin });

      setMessage({ ...message, successMessage: "Admin property changed successfully" });

      setIsAdmin(!isAdmin);

      const userModified = { ...userOrg, isAdmin: !isAdmin };
      const members = orgMembers.filter((user: UsersOrganization) => user.id !== userOrg.id);

      setOrgMembers(
        [...members, userModified].sort((a: UsersOrganization, b: UsersOrganization) =>
          a.user.email.localeCompare(b.user.email)
        )
      );

      await handleFetchAllOrganizations();
    } catch (error) {
      setMessage({ ...message, successMessage: "Admin property was not changed successfully" });
      console.log(error);
      throw Error();
    }
    setLoading(false);
  };
  useEffect(() => {
    setOrgSelected(allOrganizations.filter((org: Organization) => org.id === userOrg?.organizationId)[0]);
  }, allOrganizations);

  const handleRemoveUser = (userOrg: UsersOrganization) => {
    setCurrentUser(userOrg);
    setModalRemoveUser(true);
  };

  return (
    <tbody className="bg-white" key={userOrg.user.id}>
      <tr className="border-b-4 border-[#BEBEBE] text-center">
        <td className="py-3 w-4/12">{userOrg.user.name}</td>
        <td className="py-3 w-4/12">{userOrg.user.email}</td>
        <td className="py-3 w-2/12">
          <input type="checkbox" value="" checked={userOrg.isAdmin} onChange={handleChangeAdmin} />
        </td>
        <td className="py-3 w-2/12 ">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              className="flex cursor-pointer"
              onClick={() => handleRemoveUser(userOrg)}
            >
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
            </svg>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
