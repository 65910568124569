import { useContext } from "react";
import { Navigate } from "react-router";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ConfirmEmail from "./components/confirmEmail";
import Dashboard from "./components/dashboard/Dashboard";
import Licenses from "./components/dashboard/licenses/Licenses";
import NewOrganization from "./components/dashboard/newOrganization/newOrganization";
import Organizations from "./components/dashboard/organizations/Organizations";
import Profile from "./components/dashboard/profile/Profile";
import ForgotPassword from "./components/forgotPassword";
import Home from "./components/Home";
import SignUp from "./components/signUp/SignUp";
import AddLicenseToOrganization from "./components/sumuriAdminDashboard/addLicenseToOrganization/AddLicenseToOrganization";
import AllowedHardware from "./components/sumuriAdminDashboard/allowedHardwares/AllowedHardware";
import DisableLicense from "./components/sumuriAdminDashboard/disableLicense/DisableLicense";
import EditIssuedLicenses from "./components/sumuriAdminDashboard/editIssuedLicenses/EditIssuedLicenses";
import NewLicense from "./components/sumuriAdminDashboard/newLicense/NewLicense";
import { OrgAdministration } from "./components/sumuriAdminDashboard/orgAdministration/OrgAdministration";
import SumuriDashboard from "./components/sumuriAdminDashboard/SumuriDashboard";
import { UserAdministration } from "./components/sumuriAdminDashboard/userAdministration/UserAdministration";
import { AuthContext } from "./context/AuthContext";

function App() {
  const {
    authState: { auth, isSumuriAdmin },
  } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path="/" element={!auth ? <Home /> : <Navigate to="/dashboard" />} />
        <Route path="/signUp" element={!auth ? <SignUp /> : <Navigate to="/dashboard" />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/confirmEmail" element={<ConfirmEmail />} />
        <Route
          path="/dashboard"
          element={
            auth ? isSumuriAdmin == false ? <Dashboard /> : <Navigate to="/sumuriDashboard" /> : <Navigate to="/" />
          }
        >
          <Route path="" element={<Organizations />} />
          <Route path="licenses" element={<Licenses />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route
          path="dashboard/newOrganization"
          element={
            auth ? !isSumuriAdmin ? <NewOrganization /> : <Navigate to="/sumuriDashboard" /> : <Navigate to="/" />
          }
        />
        <Route
          path="/sumuriDashboard"
          element={auth ? isSumuriAdmin ? <SumuriDashboard /> : <Navigate to="/dashboard" /> : <Navigate to="/" />}
        >
          <Route path="" element={<AddLicenseToOrganization />} />
          <Route path="newLicense" element={<NewLicense />} />
          <Route path="disableLicense" element={<DisableLicense />} />
          <Route path="hardware" element={<AllowedHardware />} />
          <Route path="userAdministration" element={<UserAdministration />} />
          <Route path="editIssued" element={<EditIssuedLicenses />} />
          <Route path="profile" element={<Profile />} />
          <Route path="userAdministration" element={<UserAdministration />} />
          <Route path="orgAdministration" element={<OrgAdministration />} />

          <Route path="profile" element={<Profile />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
