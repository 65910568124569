import { useEffect, useState } from "react";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";

import { matchRegex } from "./util/regex";
import { ApiClient } from "./util/ApiClient";

interface getURLModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  handleGetURL: (
    index: number,
    installationName: string,
    modelId: string,
    hardwareSerial: string,
    customDuration?: number
  ) => Promise<void>;
}

function GetURLModal(props: getURLModalProps) {
  const { get } = ApiClient();
  const { setShow, index, handleGetURL } = props;
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [installationName, setInstallationName] = useState("");
  const [allowedHardwareList, setAllowedHardwareList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [customDuration, setCustomDuration] = useState("");
  const [selected, setSelected] = useState<any>(null);
  const [hardwareSerial, setHardwareSerial] = useState("");
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const matchingModel = selected.models.find((model: any) => {
      return matchRegex(hardwareSerial, window.atob(model.serialNumberRegex));
    });
    setError(false);
    try {
      await handleGetURL(index, installationName, matchingModel.id, hardwareSerial, Number(customDuration));
    } catch {
      setError(true);
      setDisabled(false);
    }
    setDisabled(false);
  };

  const fetchAllowedHardwares = async () => {
    try {
      const response = await get("allowedHardware");
      const mappedResponse = response.data.map((hardware: any) => {
        return {
          value: hardware.id,
          label: hardware.manufacturerName,
          serialNumberRegexArray: hardware.models.map((model: any) => {
            return window.atob(model.serialNumberRegex);
          }),
          models: hardware.models,
        };
      });
      setAllowedHardwareList(mappedResponse);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchAllowedHardwares();
  }, []);

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 mx-auto sm:text-center">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Issue License
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please, insert installation gUID to get your license zip file
                    </p>
                  </div>
                  {error ? (
                    <div className="mt-2">
                      <p className="text-sm text-red-500">Failed to download.</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {loading ? (
              <div className="text-center mt-10">
                <BeatLoader color="red" />
              </div>
            ) : (
              <form
                className="mt-8 space-y-6"
                onSubmit={async (event) => {
                  setDisabled(true);
                  await handleSubmit(event);
                }}
              >
                <div className="rounded-md shadow-sm -space-y-px text-center">
                  <p className="text-left ml-32">Installation Name</p>
                  <div>
                    <input
                      id="installationName"
                      name="installationName"
                      type="string"
                      autoComplete="installationName"
                      required
                      className="appearance-none rounded-none w-1/2 px-3 py-2 border-2 border-black placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                      placeholder="For your use only..."
                      value={installationName}
                      onChange={(e) => setInstallationName(e.target.value)}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px">
                  <p className="text-left ml-32">Hardware Manufacturer</p>
                  <div>
                    <Select
                      className="inline-block w-1/2 text-left border-2 border-black text-gray-900 rounded-b-md rounded-t-md"
                      placeholder="Select Manufacturer..."
                      options={allowedHardwareList}
                      onChange={(e) => setSelected(e)}
                    />
                  </div>
                </div>
                <div className="rounded-md shadow-sm -space-y-px text-center">
                  <p className="text-left ml-32">Hardware Serial Number</p>
                  <div>
                    <input
                      id="hardwareName"
                      name="hardwareName"
                      type="text"
                      pattern={selected ? selected.serialNumberRegexArray.join("|") : ""}
                      autoComplete="hardwareName"
                      required
                      className="appearance-none rounded-none w-1/2 px-3 py-2 border-2 border-black placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                      placeholder="Your hardware serial..."
                      value={hardwareSerial}
                      onChange={(e) => setHardwareSerial(e.target.value)}
                      disabled={disabled}
                    />
                  </div>
                </div>
                {checked ? (
                  <div className="rounded-md shadow-sm -space-y-px text-center">
                    <p className="text-left ml-32">Select Custom duration</p>
                    <div>
                      <input
                        id="customDuration"
                        name="customDuration"
                        type="number"
                        autoComplete="customDuration"
                        required
                        className="appearance-none rounded-none w-1/2 px-3 py-2 border-2 border-black placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                        placeholder="Duration in days..."
                        value={customDuration}
                        max="7"
                        onChange={(e) => setCustomDuration(Number(e.target.value) > 7 ? "7" : e.target.value)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                ) : null}
                <input type="checkbox" id="myCheck" onClick={() => setChecked(!checked)} />
                <span>Select Custom Duration</span>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-500"
                    disabled={disabled || !hardwareSerial || !selected}
                  >
                    Get File
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-500"
                    disabled={disabled}
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetURLModal;
