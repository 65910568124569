import { useState } from "react";

import Cookies from "js-cookie";

import DeleteIssuedLicenseModal from "../../../common/DeleteIssuedLicenseModal";
import EditIssuedLicenseModal from "../../../common/EditIssuedLicenseModal";
import ErrorMessage from "../../../common/ErrorMessage";

import { organizationLicenseEditsLimit, totalEditsLimit } from "../../../common/util/editsLimits";
import { issuedLicense, orgEditsCount } from "../Licenses";
import { ApiClient } from "../../../common/util/ApiClient";

export interface issuedLicensesTabProps {
  issuedLicenses: issuedLicense[];
  isAdmin: boolean;
  refetchLicenses: () => void;
  orgEdits: orgEditsCount;
}

function IssuedLicensesTab(props: issuedLicensesTabProps) {
  const { del, patch } = ApiClient();
  const { issuedLicenses, isAdmin, refetchLicenses, orgEdits } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIssuedLicense, setSelectedIssuedLicense] = useState(0);
  const reachedEditsLimit = (orgLicenseId: string) => {
    return orgEdits.total >= totalEditsLimit || orgEdits[orgLicenseId] >= organizationLicenseEditsLimit;
  };
  const handleRemove = async (index: number, editReason: string) => {
    try {
      const issuedLicense = issuedLicenses[index];
      await del(`licenses/issuedLicense/${issuedLicense.id}`, {
        data: { userId: Cookies.get("id"), editReason },
      });
      setShowDeleteModal(false);
      refetchLicenses();
    } catch (error) {
      throw Error();
    }
  };

  const handleEdit = async (
    index: number,
    installationName: string,
    modelId: string,
    hardwareSerial: string,
    editReason: string,
    customDuration?: number
  ) => {
    try {
      const issuedLicense = issuedLicenses[index];
      const response = await patch(`licenses/editIssued/${issuedLicense.id}`, {
        issuedLicenseId: issuedLicense.id,
        installationName,
        userId: Cookies.get("id"),
        modelId,
        hardwareSerial,
        editReason,
        customDuration,
      });
      const link = document.createElement("a");
      link.href = response.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowEditModal(false);
      refetchLicenses();
    } catch (error) {
      throw Error();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      {showDeleteModal ? (
        <DeleteIssuedLicenseModal
          setShow={setShowDeleteModal}
          index={selectedIssuedLicense}
          handleRemove={handleRemove}
        />
      ) : null}
      {showEditModal ? (
        <EditIssuedLicenseModal
          setShow={setShowEditModal}
          index={selectedIssuedLicense}
          handleEdit={handleEdit}
          issuedLicense={issuedLicenses[selectedIssuedLicense]}
        />
      ) : null}
      <h2 className="mt-6 text-center text-3xl font-sans text-white">ISSUED LICENSES</h2>

      {orgEdits.total >= totalEditsLimit ? (
        <div className="flex justify-center my-4">
          <ErrorMessage message="You have reached your organizations Edit Limit. Please, contact support at xxx@xxx.com." />
        </div>
      ) : null}
      <div className="flex justify-center">
        {issuedLicenses.length ? (
          <table className="table-auto mt-4 w-4/5">
            <thead>
              <tr className="bg-[#ffce00]">
                <th className="border-black border-b-4 py-2">Product</th>
                <th className="border-black border-4 border-t-0 py-2">License Type</th>
                <th className="border-black border-4 border-t-0 py-2">Installation Name</th>
                <th className="border-black border-4 border-t-0 py-2">Expiry Date</th>
                {isAdmin ? <th className="border-black border-b-4 py-2">User Email</th> : null}
                <th className="border-black border-4 border-t-0 border-r-0 py-2">Download</th>
                {isAdmin ? <th className="border-black border-b-4 "></th> : null}
                {isAdmin ? <th className="border-black border-b-4 "></th> : null}
              </tr>
            </thead>
            <tbody className="bg-white">
              {issuedLicenses.map((issuedLicense, i) => {
                return (
                  <tr key={i} className="border-b-4 border-[#BEBEBE]">
                    <td className="py-2"> {issuedLicense.productName}</td>
                    <td> {issuedLicense.licenseType} </td>
                    <td> {issuedLicense.installationGUId} </td>
                    <td>{new Date(issuedLicense.expiryDate).toLocaleDateString("en-US")}</td>
                    {isAdmin ? <td>{issuedLicense.requestingUser}</td> : null}
                    <td>
                      {new Date(issuedLicense.expiryDate) > new Date() ? (
                        <a href={issuedLicense.url} download>
                          <button className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]">
                            Download
                          </button>
                        </a>
                      ) : null}
                    </td>
                    {isAdmin ? (
                      <td>
                        {new Date(issuedLicense.expiryDate) > new Date() ? (
                          <button
                            onClick={() => {
                              setShowEditModal(true);
                              setSelectedIssuedLicense(i);
                            }}
                            disabled={reachedEditsLimit(issuedLicense.orgLicenseId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke={reachedEditsLimit(issuedLicense.orgLicenseId) ? "currentColor" : "green"}
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </button>
                        ) : null}
                      </td>
                    ) : null}
                    {isAdmin ? (
                      <td>
                        {new Date(issuedLicense.expiryDate) > new Date() ? (
                          <button
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedIssuedLicense(i);
                            }}
                            className="text-green"
                            disabled={reachedEditsLimit(issuedLicense.orgLicenseId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke={reachedEditsLimit(issuedLicense.orgLicenseId) ? "currentColor" : "red"}
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-sans text-white">
            Selected organization has no issued licenses
          </h2>
        )}
      </div>
    </div>
  );
}

export default IssuedLicensesTab;
