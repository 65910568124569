// Generated by https://quicktype.io

export interface UsersOrganization {
  id: string;
  organizationId: string;
  userId: string;
  isAdmin: boolean;
  organization: Organization;
  user: User;
}

export interface Organization {
  id: string;
  name: string;
  usersOrganizations: UsersOrganization[];
  organizationLicenses: OrganizationLicenses[];
}

export interface User {
  id: string;
  name: string;
  email: string;
  isSumuriAdmin: string;
  usersOrganizations: UsersOrganization[];
}

export enum OrganizationTitle {
  ORG_ADM = "ORGANIZATION ADMINISTRATION",
  ORG_MEMBERS = "ORGANIZATION MEMBERS",
  ORG_NAME = "ORGANIZATION NAME",
}

export interface OrganizationLicenses {
  id: string;
  purchaseDate: string;
  organizationId: string;
  licenseTypeId: string;
  expiryDate: string;
  quantity: number;
  licenseType: LicenseType;
}

export interface LicenseType {
  id: string;
  name: string;
  duration: number;
  productId: string;
}
