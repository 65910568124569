import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import BeatLoader from "react-spinners/BeatLoader";

import ErrorMessage from "../../common/ErrorMessage";
import SuccessMessage from "../../common/SuccessMessage";

import { organization } from "../../dashboard/Dashboard";

import "react-datepicker/dist/react-datepicker.css";
import { ApiClient } from "../../common/util/ApiClient";

function NewLicense() {
  const { get, post } = ApiClient();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [licenseName, setLicenseName] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [duration, setDuration] = useState<number | null>();

  const handleFetchProducts = async () => {
    setLoading(true);
    try {
      const responseProducts = await get("licenses/products");
      const optionsProducts = responseProducts.data.map((prod: any) => {
        return { value: prod.id, label: prod.name };
      });
      setProducts(optionsProducts);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const submitAddLicenseToProduct = async (e: any) => {
    e.preventDefault();
    setSuccess(false);
    try {
      const body = {
        name: licenseName,
        duration: duration,
        productId: "",
        newProductName: "",
      };
      if (selectedProduct.value == selectedProduct.label) {
        body.newProductName = selectedProduct.value;
      } else {
        body.productId = selectedProduct.value;
      }
      await post("/licenses/addLicense/", body);
      setLicenseName("");
      setDuration(0);
      setSelectedProduct(null);
      setSuccess(true);
      handleFetchProducts();
    } catch {
      setError(true);
    }
    setDisabled(false);
  };

  useEffect(() => {
    handleFetchProducts();
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="mt-12 text-center text-3xl font-sans text-white">ADD LICENSE TYPE TO PRODUCT</h2>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="min-h-full flex items-center justify-center pt-6 pb-3 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <form
              className="mt-8 space-y-6"
              onSubmit={(e: any) => {
                setDisabled(true);
                submitAddLicenseToProduct(e);
              }}
            >
              {error ? <ErrorMessage message="Failed to add License Type to Product" /> : null}
              {success ? <SuccessMessage message="Successfully added License Type to Product" /> : null}

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <div>
                    <Creatable
                      className="inline-block mr-8 w-full"
                      options={products}
                      value={selectedProduct}
                      onChange={(e) => setSelectedProduct(e)}
                      placeholder="Select Product"
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <input
                    id="productName"
                    name="productName"
                    type="string"
                    autoComplete="productName"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-1 border-2 border-white placeholder-gray-500 text-gray-900 rounded-b-md text-center rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                    placeholder="License Name"
                    value={licenseName}
                    onChange={(e) => setLicenseName(e.target.value)}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div>
                <div>
                  <input
                    id="duration"
                    name="duration"
                    type="number"
                    autoComplete="duration"
                    required
                    className="appearance-none text-center rounded-none relative block w-full px-3 py-1 border-2 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                    placeholder="Duration in Days"
                    value={duration || ""}
                    onChange={(e) => setDuration(Number(e.target.value))}
                    disabled={disabled}
                    min="0"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] disabled:bg-gray-500"
                  disabled={disabled || !licenseName || !duration || !selectedProduct}
                >
                  Add License Type to Product
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewLicense;
