import { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

import { OrganizationContext } from "../../../context/OrganizationContext";
import ChangeLicenseCountModal from "../../common/ChangeLicenceCountModal";
import { Organization, OrganizationLicenses } from "../../common/interfaces";

import { ShowLicenses } from "./ShowLicenses";
import { ApiClient } from "../../common/util/ApiClient";

interface Props {
  org: Organization;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => Promise<void>;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
}

export const OrganizationProfile = ({ org, setMessage, handleFetchAllOrganizations, setOrgSelected }: Props) => {
  const { put } = ApiClient();
  const [organization, setOrganization] = useState({
    name: "",
  });
  const {
    orgState: { allOrganizations },
  } = useContext(OrganizationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [currentLicense, setCurrentLicense] = useState<OrganizationLicenses | null>(null);
  const [licenseCount, setLicenseCount] = useState<number | undefined>(currentLicense?.quantity);

  const handleUserInput = (name: string, value: string) => {
    setOrganization({ ...organization, name: value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const message = {
      successMessage: "",
      errorMessage: "",
    };

    try {
      await put(`organizations/${org.id}`, { newOrgName: organization.name });
      setMessage({ ...message, successMessage: "Organization renamed successfuly" });
      handleFetchAllOrganizations();
    } catch (error) {
      setMessage({ ...message, successMessage: "Organization was not renamed, please try again" });
      console.log(error);
      throw Error();
    }

    setLoading(false);
  };

  useEffect(() => {
    const message = {
      successMessage: "",
      errorMessage: "",
    };
    setOrganization({ name: org?.name });
    setMessage(message);
  }, []);

  const handleChangeLicenseCount = async () => {
    const message = {
      successMessage: "",
      errorMessage: "",
    };
    setLoading(true);
    try {
      await put(`licenses/OrganizationLicense`, { id: currentLicense?.id, quantity: licenseCount });
      await handleFetchAllOrganizations();
      setMessage({ ...message, successMessage: "License count changed successfully" });
    } catch (error) {
      setMessage({ ...message, successMessage: "License count not changed successfully, please try again" });
      console.log(error);
      throw Error();
    }
    setLoading(false);
  };

  useEffect(() => {
    setLicenseCount(currentLicense?.quantity);
  }, [currentLicense]);

  useEffect(() => {
    setOrgSelected(allOrganizations.filter((orgUpdated: Organization) => orgUpdated.id === org.id)[0]);
  }, [allOrganizations]);

  return (
    <div className="justify-center text-center items-center flex flex-col ">
      {show && (
        <ChangeLicenseCountModal
          setShow={setShow}
          handleChangeLicenseCount={handleChangeLicenseCount}
          currentLicense={currentLicense}
          setLicenseCount={setLicenseCount}
          licenseCount={licenseCount}
        />
      )}

      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <>
          <div className="flex flex-col w-6/12">
            <div className=" mb-2 font-medium text-gray-500 border border-gray-500 rounded-xl py-14 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 py-4 px-4">
              <form className="ml-4">
                <div className="flex items-center mt-5 mb-5">
                  <div className="pr-5">
                    <label htmlFor="username" className="text-white">
                      Name
                    </label>
                  </div>
                  <div className="mr-3">
                    <input
                      id="name"
                      name="name"
                      type="string"
                      placeholder="Enter Org Name"
                      required
                      value={organization.name}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="text-right mt-2 mb-4">
              <button
                type="submit"
                onClick={handleSubmit}
                className="py-2 px-4 mr-3 border border-transparent bg-[#cca500] text-sm font-medium  rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1"
              >
                Save
              </button>
            </div>
          </div>
          <ShowLicenses setShow={setShow} org={org} setCurrentLicense={setCurrentLicense} />
        </>
      )}
    </div>
  );
};
