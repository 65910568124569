import { createContext, ReactNode, useReducer } from "react";

import { Organization, UsersOrganization } from "../components/common/interfaces";
import { organizationReducer } from "./organizationReducer";

export interface OrganizationState {
  userOrganizations: UsersOrganization[];
  allOrganizations: Organization[];
}

export const orgInitialState: OrganizationState = {
  userOrganizations: [],
  allOrganizations: [],
};

export interface OrgContextProps {
  orgState: OrganizationState;
  addOrganizations: (organizations: UsersOrganization[]) => void;
  removeOrganization: (id: string) => void;
  addAllOrganizations: (organizations: Organization[]) => void;
}

export const OrganizationContext = createContext({} as OrgContextProps);

interface Props {
  children: ReactNode;
}

export const OrganizationProvider = ({ children }: Props) => {
  const [orgState, dispatch] = useReducer(organizationReducer, orgInitialState);

  const addOrganizations = (organizations: UsersOrganization[]) => {
    dispatch({ type: "addOrganizations", payload: organizations });
  };
  const removeOrganization = (id: string) => {
    dispatch({ type: "removeOrganization", payload: id });
  };

  const addAllOrganizations = async (organizations: Organization[]) => {
    await dispatch({ type: "addAllOrganizations", payload: organizations });
  };

  return (
    <OrganizationContext.Provider value={{ orgState, addOrganizations, removeOrganization, addAllOrganizations }}>
      {children}
    </OrganizationContext.Provider>
  );
};
