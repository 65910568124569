import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { BaseURL } from "./baseURL";
import axios from "axios";

export const ApiClient = () => {
  const { logout } = useContext(AuthContext);

  const navigate = useNavigate();
  // Create a new axios instance
  const api = axios.create({
    baseURL: BaseURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      navigate("/");
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        console.log("ejecutado");
        navigate("/");
        logout();
      }

      return Promise.reject(error);
    }
  );

  const get = (path: string) => {
    return api.get(path).then((response) => response);
  };

  const post = (path: string, data: any) => {
    return api.post(path, data).then((response) => response);
  };

  const put = (path: string, data: any) => {
    return api.put(path, data).then((response) => response);
  };

  const del = (path: string, data?: any) => {
    return api.delete(path, data).then((response) => response);
  };

  const patch = (path: string, data?: any) => {
    return api.patch(path, data).then((response) => response);
  };

  return {
    get,
    post,
    put,
    del,
    patch,
  };
};
