import { FormEvent } from "react";

interface Props {
  handleEmailSubmit: (event: FormEvent<HTMLFormElement>) => void;
  formInput: {
    email: string;
    confirmEmail: string;
    passwordForEmail: string;
    oldPassword: string;
    password: string;
    confirmPassword: string;
  };
  handleUserInput: (name: string, value: string) => void;
  emailFormError: { email: string; confirmEmail: string };
}

export const ChangeEmail = ({ handleEmailSubmit, formInput, handleUserInput, emailFormError }: Props) => {
  return (
    <div>
      <form onSubmit={handleEmailSubmit}>
        <div className="flex items-center">
          <div className="w-2/5">
            <label htmlFor="username" className="text-white mr-14">
              Password
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="passwordForEmail"
              name="passwordForEmail"
              type="password"
              placeholder="Enter Password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.passwordForEmail}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="flex mt-5 items-center">
          <div className="w-2/5">
            <label htmlFor="username" className="text-white mr-14">
              Email
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.email}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="flex mt-5 items-center ">
          <div className="w-2/5">
            <label htmlFor="username" className="text-white ">
              Confirm Email
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="confirmEmail"
              name="confirmEmail"
              type="email"
              placeholder="Confirm Email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.confirmEmail}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="text-right mt-4">
          <p className="text-red-500">{emailFormError.email}</p>
          <p className="text-red-500">{emailFormError.confirmEmail}</p>
          <button className="py-2 px-4 border border-transparent bg-[#cca500] text-sm font-medium  rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
