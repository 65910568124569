import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Cookies from "js-cookie";

import { OrganizationContext } from "../../context/OrganizationContext";
import Header from "../common/Header";
import SumuriAdminSidebar from "../common/SumuriAdminSidebar";

import { ApiClient } from "../common/util/ApiClient";

function SumuriDashboard() {
  const { get } = ApiClient();

  const { addOrganizations, addAllOrganizations } = useContext(OrganizationContext);
  const handleFetchOrganizations = async () => {
    try {
      const response = await get(`organizations/user/${Cookies.get("id")}`);

      addOrganizations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchAllOrganizations = async () => {
    try {
      const response = await get(`organizations/all`);
      addAllOrganizations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleFetchOrganizations();
    handleFetchAllOrganizations();
  }, []);

  return (
    <div>
      <div className="fixed w-full z-50">
        <Header />
        <SumuriAdminSidebar />
      </div>
      <div className="pt-20">
        <div>
          <div className="clear-right ml-60">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SumuriDashboard;
