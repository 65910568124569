import { FormEvent } from "react";

interface Props {
  handlePasswordSubmit: (event: FormEvent<HTMLFormElement>) => void;
  formInput: {
    email: string;
    confirmEmail: string;
    passwordForEmail: string;
    oldPassword: string;
    password: string;
    confirmPassword: string;
  };
  handleUserInput: (name: string, value: string) => void;
  passwordFormError: { password: string; confirmPassword: string };
}

export const ChangePassword = ({ handlePasswordSubmit, formInput, handleUserInput, passwordFormError }: Props) => {
  return (
    <div>
      <form onSubmit={handlePasswordSubmit} id="form-password-change">
        <p>Change Password</p>
        <div className="flex mt-5 items-center">
          <div className="w-2/5">
            <label htmlFor="oldPassword" className="text-white mr-6">
              Old Password
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="oldPassword"
              name="oldPassword"
              type="password"
              required
              placeholder="Enter Old Password"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.oldPassword}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="flex mt-5 items-center">
          <div className="w-2/5">
            <label htmlFor="password" className="text-white mr-6">
              Password
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="password"
              name="password"
              type="password"
              required
              placeholder="Enter Password"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.password}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="flex mt-5 items-center ">
          <div className="w-2/5">
            <label htmlFor="username" className="text-white">
              Confirm Password
            </label>
          </div>
          <div className="w-3/5">
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
              value={formInput.confirmPassword}
              onChange={({ target }) => {
                handleUserInput(target.name, target.value);
              }}
            />
          </div>
        </div>
        <div className="text-right mt-4">
          <p className="text-red-500">{passwordFormError.password}</p>
          <p className="text-red-500">{passwordFormError.confirmPassword}</p>
          <button className="py-2 px-4 border border-transparent bg-[#cca500] text-sm font-medium  rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
