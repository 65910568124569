import { User } from "./interfaces";

interface Props {
  setNewEmail: React.Dispatch<React.SetStateAction<string>>;
  newEmail: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmEmailChange: () => void;
  user: User | null | undefined;
}

const ChangeEmailModal = ({ setShow, setNewEmail, newEmail, handleConfirmEmailChange, user }: Props) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShow(false);
    handleConfirmEmailChange();
  };

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-10 pb-4 ">
              <div className="">
                <div className="">
                  <div className="mt-2">
                    <p className="text-base text-gray-500">
                      Please introduce the new email address for <b>{user?.name}</b>, the user will have to check their
                      inbox.
                    </p>
                  </div>
                  <div className="w-full mt-4">
                    <input
                      id="passwordForEmail"
                      name="passwordForEmail"
                      type="email"
                      placeholder="Enter New Email Address"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-[#cca500] placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                      value={newEmail}
                      onChange={({ target }) => {
                        setNewEmail(target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <form className="mt-1 space-y-6" onSubmit={handleSubmit}>
              <div className="bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium   sm:ml-3 sm:w-auto sm:text-sm  bg-[#cca500] text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 "
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-500"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeEmailModal;
