import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

import Cookies from "js-cookie";

import ErrorMessage from "../../../common/ErrorMessage";

import { invite } from "../invitesTab/InvitesTab";
import MyInvitesTable from "../invitesTab/tables/MyInvitesTable";
import { ApiClient } from "../../../common/util/ApiClient";

interface NewUserViewProps {
  handleFetchOrganizations: () => void;
}

function NewUserView(props: NewUserViewProps) {
  const { get, post } = ApiClient();
  const { handleFetchOrganizations } = props;
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [myInvites, setMyInvites] = useState<invite[]>([]);

  const submitNewOrganization = async (event: any) => {
    event.preventDefault();
    setError(false);
    setSubmitted(true);
    try {
      await post("/organizations", { name: newOrganizationName, userId: Cookies.get("id") });
      handleFetchOrganizations();
    } catch (error) {
      setError(true);
    }
    setSubmitted(false);
  };
  const handleGetInvites = async () => {
    setLoading(true);
    try {
      const response = await get(`organizations/invite/${Cookies.get("id")}`);
      const responseInvites: invite[] = response.data.map((invite: any) => {
        return {
          orgInviteId: invite.id,
          orgName: invite.organization.name,
          invitingUserName: invite.invitingUser.name,
          createdAt: invite.created_at,
        };
      });
      setMyInvites(responseInvites);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetInvites();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="clear-right ">
          <h2 className="pt-6 text-center text-3xl font-sans text-white">YOU HAVE NO ORGANIZATIONS </h2>
          <div className="min-h-full flex items-center justify-center pt-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <h2 className="text-center text-3xl font-sans text-white">CREATE A NEW ORGANIZATION</h2>
              </div>
              <form className="mt-8 space-y-6" onSubmit={submitNewOrganization}>
                {error ? <ErrorMessage message="Failed to create Organization." /> : null}
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <input
                      id="fullName"
                      name="name"
                      type="string"
                      autoComplete="name"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                      placeholder="Organization Name"
                      value={newOrganizationName}
                      onChange={(e) => setNewOrganizationName(e.target.value)}
                      disabled={submitted}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                    disabled={submitted}
                  >
                    Create new Organization
                  </button>
                </div>
              </form>
            </div>
          </div>
          {myInvites.length ? (
            <MyInvitesTable
              invites={myInvites}
              handleGetInvites={handleGetInvites}
              handleFetchOrganizations={handleFetchOrganizations}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NewUserView;
