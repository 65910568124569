import { Dispatch, SetStateAction } from "react";

import { Organization } from "../../common/interfaces";
import { OrganizationProfile } from "./OrganizationProfile";

interface OrganizationProfileProps {
  orgSelected: Organization;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => Promise<void>;
  handleGoBackEditOrg: () => void;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
}

export const OrganizationProfileComponent = ({
  orgSelected,
  setMessage,
  handleFetchAllOrganizations,
  handleGoBackEditOrg,
  setOrgSelected,
}: OrganizationProfileProps) => {
  return (
    <div className="text-center">
      <OrganizationProfile
        setOrgSelected={setOrgSelected}
        org={orgSelected}
        setMessage={setMessage}
        handleFetchAllOrganizations={handleFetchAllOrganizations}
      />
      <button
        onClick={handleGoBackEditOrg}
        className="py-2 px-4 mt-4 border border-transparent bg-[#cca500] text-sm font-medium rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1"
      >
        Back
      </button>
    </div>
  );
};
