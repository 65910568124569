import { Dispatch, SetStateAction } from "react";

import { Organization, UsersOrganization } from "../../common/interfaces";
import { OrgRow } from "./OrgRow";

interface Props {
  setAllOrgs: Dispatch<SetStateAction<Organization[]>>;
  allOrgs: Organization[];
  setShowMembers: Dispatch<SetStateAction<boolean>>;
  setOrgMembers: Dispatch<SetStateAction<UsersOrganization[]>>;
  setShowEditOrg: Dispatch<SetStateAction<boolean>>;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setRemoveOrgModal: Dispatch<SetStateAction<boolean>>;
}

export const OrgAdministrationTable = ({
  setAllOrgs,
  allOrgs,
  setShowMembers,
  setOrgMembers,
  setShowEditOrg,
  setOrgSelected,
  setTitle,
  setRemoveOrgModal,
}: Props) => {
  return (
    <table className="table-auto mt-4 w-full max-w-full">
      <thead>
        <tr className="bg-[#ffce00]">
          <th className="border-black border-4 border-t-0 py-2">
            <div className="flex justify-center">
              <div>Org. Name</div>
              <div className="flex justify-end ml-2 cursor-pointer" onClick={() => setAllOrgs([...allOrgs.reverse()])}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                    <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                    <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                  </svg>
                </div>
              </div>
            </div>
          </th>
          <th className="border-black border-4 border-t-0 py-2">Admin</th>
          <th className="border-black border-4 border-t-0 py-2 px-2">Edit Org.</th>
          <th className="border-black border-4 border-t-0 py-2 px-2">View Members</th>
          <th className="border-black border-4 border-t-0 py-2 px-2">Remove Org.</th>
        </tr>
      </thead>
      {allOrgs.map((org: Organization) => (
        <OrgRow
          setShowMembers={setShowMembers}
          setOrgMembers={setOrgMembers}
          org={org}
          setShowEditOrg={setShowEditOrg}
          setOrgSelected={setOrgSelected}
          setTitle={setTitle}
          setRemoveOrgModal={setRemoveOrgModal}
        />
      ))}
    </table>
  );
};
