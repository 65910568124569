import { Organization, UsersOrganization } from "../../common/interfaces";

interface Props {
  emailAuthenticated: string | undefined;
  handleClickLeaveOrg: (userOrganizationId: string, userOrganization: Organization) => void;
  userOrganizations: UsersOrganization[] | undefined;
}

export const OrganizationList = ({ emailAuthenticated, userOrganizations, handleClickLeaveOrg }: Props) => {
  return (
    <div className="items-center justify-between w-1/2 font-medium text-gray-500 border border-gray-500 py-14 rounded-xl mt-8 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 py-4 px-4">
      <div className="ml-8">
        <div className="flex flex-column">
          <div className="flex items-center">
            <label htmlFor="username" className="text-white mr-14 ">
              Email
            </label>
            <p className="bg-[#ffce00] w-full py-2 pl-4 rounded-b-md rounded-t-md pr-4">{emailAuthenticated}</p>
          </div>
        </div>
      </div>
      <div className="border rounded-lg overflow-hidden border-black mt-8 ml-4">
        <table className=" w-full rounded-t-md">
          <thead className="flex w-full">
            <tr className="flex w-full bg-[#ffce00] text-center ">
              <th className="w-2/4  py-2  rounded-tl-lg ">Organization</th>
              <th className="w-2/4  py-2  rounded-tr-lg ">Leave Organization</th>
            </tr>
          </thead>

          <tbody className="flex flex-col justify-between overflow-y-scroll w-full" style={{ maxHeight: "205px" }}>
            {userOrganizations?.map((userOrganization: UsersOrganization) => (
              <tr className="flex w-full bg-white text-center ">
                <td className="w-2/4 py-2 flex items-center justify-center">{userOrganization.organization.name}</td>
                <td className="w-2/4 py-2 flex items-center justify-center">
                  <td className="">
                    <svg
                      className="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ margin: "0 auto" }}
                      height="1em"
                      viewBox="0 0 512 512"
                      onClick={() => handleClickLeaveOrg(userOrganization.id, userOrganization.organization)}
                    >
                      <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                    </svg>
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
