import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import Cookies from "js-cookie";

import DeleteUserModal from "../../../common/DeleteUserModal";

import { organization } from "../../Dashboard";
import { user } from "../Organizations";
import { ApiClient } from "../../../common/util/ApiClient";

export interface membersTabProps {
  users: user[];
  isAdmin: boolean;
}

function MembersTab(props: membersTabProps) {
  const { del } = ApiClient();
  const { users, isAdmin } = props;
  const { patch } = ApiClient();
  const [loading, setLoading] = useState(false);
  const myId = Cookies.get("id");
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState(0);
  const { handleFetchOrganizations } =
    useOutletContext<{ selectedOrganization: organization; handleFetchOrganizations: () => void }>() || props;

  const handlePromote = async (index: number, isAdmin: boolean) => {
    setLoading(true);
    try {
      const user = users[index];
      await patch(`organizations/promote`, { userOrgId: user.userOrgId, isAdmin: isAdmin, userId: user.id });
      users[index].isAdmin = isAdmin;
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRemove = async (index: number) => {
    try {
      const user = users[index];
      await del(`organizations/members/${user.userOrgId}`);
      if (user.id === myId) {
        handleFetchOrganizations();
      }
      users.splice(index, 1);
      setShow(false);
    } catch (error) {
      throw Error();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      {show ? <DeleteUserModal setShow={setShow} index={selectedMember} handleRemove={handleRemove} /> : null}
      <h2 className="mt-6 text-center text-3xl font-sans text-white">ORGANIZATION MEMBERS</h2>
      <div className="flex justify-center">
        {users.length ? (
          <table className="table-auto mt-4 w-4/5">
            <thead>
              <tr className="bg-[#ffce00]">
                <th className="border-black border-b-4 py-2">Name</th>
                <th className="border-black border-4 border-t-0 py-2">Email</th>
                <th className="border-black border-4 border-t-0 py-2">Role</th>
                {isAdmin ? <th className="border-black border-b-4 py-2">Manage Roles</th> : null}
                {isAdmin ? <th className="border-black border-b-4 py-2"></th> : null}
              </tr>
            </thead>
            <tbody className="bg-white">
              {users.map((user, i) => {
                return (
                  <tr key={i} className="border-b-4 border-[#BEBEBE]">
                    <td> {user.name}</td>
                    <td> {user.email} </td>
                    <td className="flex justify-center py-3">{user.isAdmin ? "Admin" : "User"}</td>
                    {isAdmin ? (
                      user.id !== myId ? (
                        !user.isAdmin ? (
                          <td>
                            <button
                              className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                              disabled={loading}
                              onClick={() => handlePromote(i, true)}
                            >
                              Promote
                            </button>
                          </td>
                        ) : (
                          <td>
                            <button
                              className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                              disabled={loading}
                              onClick={() => handlePromote(i, false)}
                            >
                              Demote
                            </button>
                          </td>
                        )
                      ) : (
                        <td></td>
                      )
                    ) : null}
                    {isAdmin ? (
                      user.id === myId ? (
                        users.some((user) => user.id !== myId && user.isAdmin) ? (
                          <td>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                              onClick={() => {
                                setShow(true);
                                setSelectedMember(i);
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </td>
                        ) : (
                          <td></td>
                        )
                      ) : (
                        <td>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            onClick={() => {
                              setShow(true);
                              setSelectedMember(i);
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </td>
                      )
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-sans text-white">Selected organization has no users</h2>
        )}
      </div>
    </div>
  );
}

export default MembersTab;
