import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import Cookies from "js-cookie";

import { organization } from "../../../Dashboard";
import { invite } from "../InvitesTab";
import { ApiClient } from "../../../../common/util/ApiClient";

interface myInvitesTableProps {
  invites: invite[];
  handleGetInvites: () => void;
  handleFetchOrganizations?: () => void;
}

function MyInvitesTable(props: myInvitesTableProps) {
  const { post, patch } = ApiClient();
  const { invites, handleGetInvites } = props;
  const { handleFetchOrganizations } =
    useOutletContext<{ selectedOrganization: organization; handleFetchOrganizations: () => void }>() || props;
  const [loading, setLoading] = useState(false);
  const handleAcceptInvite = async (index: number) => {
    setLoading(true);
    try {
      const invite = invites[index];
      await post("/organizations/invite", { orgInviteId: invite.orgInviteId, userId: Cookies.get("id") });
      invites.splice(index, 1);
      await handleFetchOrganizations();
      await handleGetInvites();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleRejectInvite = async (index: number) => {
    setLoading(true);
    try {
      const invite = invites[index];
      await patch(`organizations/invite`, {
        orgInviteId: invite.orgInviteId,
        userId: Cookies.get("id"),
      });
      await handleGetInvites();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="mt-16 text-center text-3xl font-sans text-white mb-6">My Invites</h2>
      <div className="flex justify-center">
        <table className="table-auto mt-4 w-4/5">
          <thead>
            <tr className="bg-[#ffce00]">
              <th className="border-black border-b-4 py-2">Organization Name</th>
              <th className="border-black border-4 border-t-0 py-2">Invited By</th>
              <th className="border-black border-4 border-t-0 border-r-0 py-2">Invitation Date</th>
              <th className="border-black border-b-4 py-2"></th>
              <th className="border-black border-b-4 py-2"></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {invites.map((invite, i) => {
              return (
                <tr key={i} className="border-b-4 border-[#BEBEBE]">
                  <td> {invite.orgName}</td>
                  <td> {invite.invitingUserName} </td>
                  <td> {new Date(invite.createdAt).toLocaleDateString("en-US")} </td>
                  <td>
                    {" "}
                    <button
                      className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                      disabled={loading}
                      onClick={() => handleAcceptInvite(i)}
                    >
                      Accept
                    </button>{" "}
                  </td>
                  <td>
                    {" "}
                    <button
                      className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                      disabled={loading}
                      onClick={() => handleRejectInvite(i)}
                    >
                      Reject
                    </button>{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyInvitesTable;
