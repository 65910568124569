import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

import Cookies from "js-cookie";

import ErrorMessage from "../../../common/ErrorMessage";

import { organization } from "../../Dashboard";
import { invitedUser, newUser } from "../Organizations";
import InvitedUsersTable from "./tables/InvitedUsersTable";
import MyInvitesTable from "./tables/MyInvitesTable";
import NewUsersTable from "./tables/NewUsersTable";
import { ApiClient } from "../../../common/util/ApiClient";

interface invitesTabProps {
  newUsers: newUser[];
  invitedUsers: invitedUser[];
  handleGetMembers: () => void;
  organization: organization;
}

function InvitesTab(props: invitesTabProps) {
  const { get, post } = ApiClient();
  const { newUsers, invitedUsers, handleGetMembers, organization } = props;
  const [loading, setLoading] = useState(false);
  const [myInvites, setMyInvites] = useState<invite[]>([]);
  const [error, setError] = useState(false);
  const [newInviteEmail, setNewInviteEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleGetInvites = async () => {
    setLoading(true);
    try {
      const response = await get(`organizations/invite/${Cookies.get("id")}`);
      const responseInvites: invite[] = response.data.map((invite: any) => {
        return {
          orgInviteId: invite.id,
          orgName: invite.organization.name,
          invitingUserName: invite.invitingUser.name,
          createdAt: invite.created_at,
        };
      });
      setMyInvites(responseInvites);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const submitNewInvite = async (event: any) => {
    event.preventDefault();
    setError(false);
    setSubmitted(true);
    try {
      await post(`/organizations/invite/${organization.value}`, {
        email: newInviteEmail,
        userId: Cookies.get("id"),
      });
      handleGetMembers();
    } catch (error) {
      setError(true);
    }
    setSubmitted(false);
  };

  useEffect(() => {
    handleGetInvites();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="mb-24">
          {organization ? (
            <>
              <h2 className="mt-16 text-center text-3xl font-sans text-white mb-6">
                Invite User to {organization.label}
              </h2>
              <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                  <form className="space-y-6" onSubmit={submitNewInvite}>
                    {error ? <ErrorMessage message="Failed to send Invite." /> : null}
                    <div className="rounded-md shadow-sm -space-y-px">
                      <div>
                        <input
                          id="fullName"
                          name="name"
                          type="string"
                          autoComplete="name"
                          required
                          className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                          placeholder="Email"
                          value={newInviteEmail}
                          onChange={(e) => setNewInviteEmail(e.target.value)}
                          disabled={submitted}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                        disabled={submitted}
                      >
                        Send New Invite
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : null}
          {myInvites.length ? <MyInvitesTable invites={myInvites} handleGetInvites={handleGetInvites} /> : null}
          {invitedUsers.length ? <InvitedUsersTable invitedUsers={invitedUsers} /> : null}
          {newUsers.length ? <NewUsersTable newUsers={newUsers} /> : null}
        </div>
      )}
    </div>
  );
}

export default InvitesTab;

export interface invite {
  orgInviteId: string;
  orgName: string;
  invitingUserName: string;
  createdAt: Date;
}
