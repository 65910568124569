import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Header from "../common/Header";
import ConfirmEmailEnterValues from "./ConfirmEmailEnterValues";
import EmailChanged from "./EmailChanged";

export interface StagesProps {
  setStage: (value: number) => void;
  email: string;
  setEmail: (value: string) => void;
}

function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("confirmation_code");
  const username = searchParams.get("user_name");

  const [stage, setStage] = useState(1);

  useEffect(() => {
    if (username && verificationCode) {
      setStage(1);
    }
  }, []);
  return (
    <>
      <Header />
      {stage === 1 && (
        <ConfirmEmailEnterValues setStage={setStage} username={username} verificationCode={verificationCode} />
      )}
      {stage === 2 && <EmailChanged />}
    </>
  );
}

export default ConfirmEmail;
