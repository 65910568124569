import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Cookies from "js-cookie";

import { AuthContext } from "../../context/AuthContext";

import { ApiClient } from "./util/ApiClient";

function Header() {
  const {
    authState: { auth },
    logout,
  } = useContext(AuthContext);
  const { get } = ApiClient();
  const [loading, setLoading] = useState(false);

  const submitLogout = async () => {
    setLoading(true);
    try {
      Cookies.remove("auth");
      Cookies.remove("email");
      Cookies.remove("name");
      Cookies.remove("id");
      Cookies.remove("access_token");
      Cookies.remove("isSumuriAdmin");
      logout();
      await get("users/logout");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div className="bg-[#24272d] flex flex-row py-5 px-4 w-full">
      <div className="basis-1/2">
        <Link to={auth ? "/dashboard" : "/"}>
          <img src="/SUMURI_Logo_white-1.png" alt="sumuti logo" />
        </Link>
      </div>
      {auth ? (
        <div className="basis-1/2">
          <button
            disabled={loading}
            className="basis-1/2 float-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
            onClick={submitLogout}
          >
            Logout
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
