import { FormEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";

import Cookies from "js-cookie";

import { AuthContext } from "../../context/AuthContext";
import ErrorMessage from "../common/ErrorMessage";

import { ApiClient } from "../common/util/ApiClient";

function Login() {
  const { post } = ApiClient();
  const { signIn, setSumuriAdmin } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submitLogin = async (event: FormEvent) => {
    event.preventDefault();

    setError(false);
    setLoading(true);
    try {
      const response = await post("/users/login", { email: email, password: password });

      if (response.data.auth) {
        const access_token = response.data.awsAuth.accessToken.jwtToken;
        const id_token = response.data.awsAuth.idToken.jwtToken;
        const refresh_token = response.data.awsAuth.refreshToken.token;

        Cookies.set("auth", "true");
        Cookies.set("email", email);
        Cookies.set("id", response.data.id);
        Cookies.set("name", response.data.name);
        Cookies.set("access_token", access_token);
        localStorage.setItem("access_token", access_token);
        Cookies.set("id_token", id_token);
        Cookies.set("refresh_token", refresh_token);
        if (response.data.isSumuriAdmin) {
          Cookies.set("isSumuriAdmin", response.data.isSumuriAdmin);
          setSumuriAdmin();
        }
        signIn(email, access_token, id_token, refresh_token);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="min-h-full flex items-center justify-center pt-12 pb-3 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src="/logo_black.png" alt="sumuti logo black" />
          <h2 className="mt-6 text-center text-3xl font-sans text-white">LOGIN TO YOUR ACCOUNT</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={submitLogin}>
          {error ? <ErrorMessage message="Email/Password combination is not correct." /> : null}
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </div>
          </div>

          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to={"/forgotPassword"}>
                <p className="font-medium text-[#ffce00] hover:text-[#cca500]"> Forgot your password? </p>
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
              disabled={loading}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-gray-900 group-hover:text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
