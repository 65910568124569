import React, { useState } from "react";

import { User } from "../interfaces";

import { ApiClient } from "../util/ApiClient";

export const useCustomAdminUser = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setModalChangeEmail: React.Dispatch<React.SetStateAction<boolean>>,
  setModalRemoveUser: React.Dispatch<React.SetStateAction<boolean>>,
  getAllUsers: () => void,
  setShowOrganizations: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { post } = ApiClient();
  const [currentUser, setCurrentUser] = useState<any>();
  const [newEmail, setNewEmail] = useState<string>("");

  const [message, setMessage] = useState({
    successMessage: "",
    errorMessage: "",
  });

  const handleChangeEmail = async (user: User) => {
    setNewEmail("");
    setCurrentUser(user);
    setModalChangeEmail(true);
  };

  const handleConfirmEmailChange = async () => {
    const message = { successMessage: "", errorMessage: "" };
    try {
      if (currentUser?.email) {
        await post("/users/adminUpdateUserAttribute", {
          field: "email",
          value: newEmail,
          username: currentUser.email,
        });
      }
      setMessage({ ...message, successMessage: "Email verification link sent successfully" });
      getAllUsers();
    } catch (error) {
      console.log(error);
      setMessage({ ...message, errorMessage: "Failed to send email verification code" });
      throw Error();
    }
    setCurrentUser(null);
  };

  const handleSendLink = async (email: string) => {
    const message = { successMessage: "", errorMessage: "" };

    setLoading(true);
    try {
      await post("/users/verifyEmail", { email });
      setMessage({ ...message, successMessage: "Reset password link sent successfully" });
    } catch (error) {
      setMessage({ ...message, errorMessage: "Failed to send Reset password verification link" });
    }
    setLoading(false);
  };

  const handleRemoveUser = (user: User) => {
    setCurrentUser(user);
    setModalRemoveUser(true);
  };

  const handleConfirmDeleteUser = async () => {
    const message = { successMessage: "", errorMessage: "" };

    setLoading(true);
    try {
      await post("/users/deleteUser", { id: currentUser?.id, username: currentUser?.email });
      setMessage({ ...message, successMessage: "User deleted successfully" });
      getAllUsers();
    } catch (error) {
      setMessage({ ...message, errorMessage: "Failed to delete user" });
    }
    setLoading(false);
    setCurrentUser(null);
  };

  const handleOrganizations = (user: User) => {
    setCurrentUser(user);
    setShowOrganizations(true);
  };

  return {
    handleConfirmDeleteUser,
    handleRemoveUser,
    handleSendLink,
    handleConfirmEmailChange,
    handleChangeEmail,
    message,
    currentUser,
    setNewEmail,
    newEmail,
    setCurrentUser,
    setMessage,
    handleOrganizations,
  };
};
