import { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import Creatable from "react-select/creatable";
import BeatLoader from "react-spinners/BeatLoader";

import ErrorMessage from "../../common/ErrorMessage";
import SuccessMessage from "../../common/SuccessMessage";

import { ApiClient } from "../../common/util/ApiClient";

export interface LabelValueInterface {
  label: string;
  value: string;
}

export interface LabelValueInterface {
  label: string;
  value: string;
}

export interface ModelInterface {
  id: string;
  modelName: string;
  serialNumberRegex: string;
  status: string;
  statusChangedDate?: Date;
}

function AllowedHardware() {
  const { get, post, del } = ApiClient();
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [modelName, setModelName] = useState("");
  const [regex, setRegex] = useState("");
  const [validatingString, setValidatingString] = useState("");
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [allowedHardwares, setAllowedHardwares] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [allowedHardwareList, setAllowedHardwareList] = useState<LabelValueInterface[]>([]);
  const [selectedOption, setSelectedOption] = useState<SingleValue<LabelValueInterface>>();

  const validateRegex = () => {
    const convertedRegex = new RegExp(regex);
    setValidated(convertedRegex.test(validatingString));
  };

  const submitNewHardware = async (e: any) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    try {
      const converted = window.btoa(regex);

      const body = {
        manufacturerName: selectedOption?.value,
        modelName: modelName,
        serialNumberRegex: converted,
      };
      await post("/allowedHardware", body);
      setModelName("");
      setValidatingString("");
      setRegex("");
      setSelectedOption(null);
      setValidated(false);
      setSuccess(true);
      refetchAllowedHardwares();
    } catch {
      setError(true);
    }
    setDisabled(false);
  };

  const deleteHardware = async (id: string) => {
    try {
      await del(`allowedHardware/${id}`);
      refetchAllowedHardwares();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllowedHardwares = async () => {
    try {
      const response = await get("allowedHardware");
      setAllowedHardwares(response.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const fetchAllowedHardwareList = async () => {
    try {
      const response = await get("allowedHardware/list");
      setAllowedHardwareList(
        response.data.map((allowedHardware: any) => {
          return {
            label: allowedHardware.manufacturerName,
            value: allowedHardware.manufacturerName,
          };
        })
      );
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const refetchAllowedHardwares = () => {
    setLoading(true);
    fetchAllowedHardwares();
    fetchAllowedHardwareList();
  };

  useEffect(() => {
    setLoading(true);
    fetchAllowedHardwares();
    fetchAllowedHardwareList();
  }, []);

  return (
    <div>
      <div className="min-h-full flex items-center justify-center pb-3 px-4 sm:px-6 lg:px-8 mb-12">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="my-12 text-center text-3xl font-sans text-white">ALLOWED HARDWARE</h2>
          </div>
          <form
            className="space-y-6"
            onSubmit={(e) => {
              setDisabled(true);
              submitNewHardware(e);
            }}
          >
            {error ? <ErrorMessage message="Failed to create new hardware" /> : null}
            {success ? <SuccessMessage message="Successfully created new hardware" /> : null}
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <Creatable
                  placeholder="Select/Create Allowed Hardware..."
                  options={allowedHardwareList}
                  isDisabled={disabled}
                  className="text-left float-left w-full mb-6"
                  value={selectedOption}
                  onChange={(e) => {
                    setSelectedOption(e);
                  }}
                />
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="modelName"
                  name="modelName"
                  type="string"
                  autoComplete="modelName"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                  placeholder="Model Name"
                  value={modelName}
                  onChange={(e) => setModelName(e.target.value)}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="regex"
                  name="regex"
                  type="string"
                  autoComplete="regex"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                  placeholder="Hardware Regex"
                  value={regex}
                  onChange={(e) => {
                    setRegex(e.target.value);
                    setValidated(false);
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="regexTest"
                  name="regexTest"
                  type="text"
                  autoComplete="regexTest"
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-${
                    validated ? "[#50C878]" : "[#FF5733]"
                  } placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white`}
                  placeholder="Test Regex"
                  disabled={disabled}
                  value={validatingString}
                  onChange={(e) => setValidatingString(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className={
                  validated
                    ? "group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-[#50C878] disabled:bg-gray-500"
                    : "group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-[#FF5733] disabled:bg-gray-500"
                }
                disabled={!regex}
                onClick={() => validateRegex()}
              >
                Validate Regex
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] disabled:bg-gray-500"
                disabled={disabled || !modelName || !regex || !validated || !selectedOption}
              >
                Create new Hardware
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-full flex items-center justify-center px-48 mb-24">
        {loading ? (
          <div className="text-center mt-10">
            <BeatLoader color="yellow" />
          </div>
        ) : allowedHardwares.length ? (
          <table className="table-auto mt-4 w-full max-w-full">
            <thead>
              <tr className="bg-[#ffce00]">
                <th className="border-black border-b-4 py-2">Manufacturer Name</th>
                <th className="border-black border-4 border-t-0 border-r-0 py-2">Model Name</th>
                <th className="border-black border-4 border-t-0 border-r-0 py-2">Regex</th>
                <th className="border-black border-b-4 py-2"></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {allowedHardwares.map((allowedHardware, i) => {
                return (
                  <>
                    <tr key={i} className="text-center border-t-4">
                      <td className="py-3" rowSpan={allowedHardware.models.length + 1}>
                        {allowedHardware.manufacturerName}
                      </td>
                    </tr>
                    {allowedHardware.models.map((model: ModelInterface) => {
                      return (
                        <tr className="border-black text-center">
                          <td className="py-2"> {model.modelName} </td>
                          <td> {window.atob(model.serialNumberRegex)} </td>
                          <td>
                            <button onClick={async () => await deleteHardware(model.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-sans text-white">No Allowed Hardware with Active Models</h2>
        )}
      </div>
    </div>
  );
}

export default AllowedHardware;
