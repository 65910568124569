import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

import ChangeEmailModal from "../../common/ChangeEmailModal";
import ErrorMessage from "../../common/ErrorMessage";
import { useCustomAdminUser } from "../../common/hooks/useCustomAdminUser";
import { useLeaveOrganization } from "../../common/hooks/useLeaveOrganization";
import { User, UsersOrganization } from "../../common/interfaces";
import LeaveOrgModal from "../../common/LeaveOrgModal";
import RemoveUserModal from "../../common/RemoveUserModal";
import SuccessMessage from "../../common/SuccessMessage";

import { OrganizationList } from "../../dashboard/profile/OrganizationList";
import { UserRow } from "./UserRow";
import { ApiClient } from "../../common/util/ApiClient";

export const UserAdministration = () => {
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { get } = ApiClient();
  const [modalChangeEmail, setModalChangeEmail] = useState<boolean>(false);
  const [modalRemoveUser, setModalRemoveUser] = useState<boolean>(false);
  const [showOrganizations, setShowOrganizations] = useState(false);

  const [show, setShow] = useState<boolean>(false);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const users = await get(`users/getAllUsers`);
      setAllUsers(users.data.sort((a: User, b: User) => a.email.localeCompare(b.email)));
    } catch (error) {
      console.log(error);
      throw Error();
    }
    setLoading(false);
  };
  const {
    handleConfirmDeleteUser,
    handleRemoveUser,
    handleSendLink,
    handleConfirmEmailChange,
    handleChangeEmail,
    message,
    currentUser,
    setNewEmail,
    newEmail,
    setMessage,
    handleOrganizations,
    setCurrentUser,
  } = useCustomAdminUser(setLoading, setModalChangeEmail, setModalRemoveUser, getAllUsers, setShowOrganizations);
  const removeAdminOrganization = () => {
    const usersOrganizations = currentUser.usersOrganizations.filter(
      (org: UsersOrganization) => org.organizationId !== currentOrg.organizationId
    );

    const user = { ...currentUser, usersOrganizations };

    setCurrentUser(user);
  };
  const { handleLeaveOrg, currentOrg, handleClickLeaveOrg } = useLeaveOrganization(
    setMessage,
    setLoading,
    setShow,
    removeAdminOrganization
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div className="min-h-full flex items-center justify-center pb-3 px-4 sm:px-6 lg:px-8">
      {modalRemoveUser && (
        <RemoveUserModal
          setShow={setModalRemoveUser}
          handleConfirmDeleteUser={handleConfirmDeleteUser}
          user={currentUser}
        />
      )}
      {modalChangeEmail && (
        <ChangeEmailModal
          setShow={setModalChangeEmail}
          setNewEmail={setNewEmail}
          newEmail={newEmail}
          handleConfirmEmailChange={handleConfirmEmailChange}
          user={currentUser}
        />
      )}

      <div className="w-full space-y-8">
        <div>
          <div className="flex justify-center mt-8 text-center">
            <div className="w-100">
              {message.successMessage && <SuccessMessage message={message.successMessage} />}
              {message.errorMessage && <ErrorMessage message={message.errorMessage} />}
            </div>
          </div>
          <h2 className="my-12 text-center text-3xl font-sans text-white">USER ADMINISTRATION</h2>
        </div>
        <div className={`w-full flex items-center justify-center mb-24 ${showOrganizations && "flex-col"}`}>
          {loading ? (
            <div className="text-center mt-10">
              <BeatLoader color="yellow" />
            </div>
          ) : (
            <>
              {showOrganizations ? (
                <>
                  <>
                    {show && (
                      <LeaveOrgModal
                        setShow={setShow}
                        handleLeaveOrg={handleLeaveOrg}
                        orgName={currentOrg.organizationName}
                      />
                    )}
                    <OrganizationList
                      emailAuthenticated={currentUser?.email}
                      handleClickLeaveOrg={handleClickLeaveOrg}
                      userOrganizations={currentUser?.usersOrganizations}
                    />

                    <button
                      onClick={() => setShowOrganizations(false)}
                      className="py-2 px-4 mt-4 border border-transparent bg-[#cca500] text-sm font-medium rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1"
                    >
                      Back
                    </button>
                  </>
                </>
              ) : (
                <table className="table-auto mt-4 w-full max-w-full">
                  <thead>
                    <tr className="bg-[#ffce00]">
                      <th className="border-black border-4 border-t-0 py-2">User Name</th>
                      <th className="border-black border-4 border-t-0 py-2">
                        <div className="flex justify-center">
                          <div>Email</div>
                          <div
                            className="flex justify-end ml-2 cursor-pointer"
                            onClick={() => setAllUsers([...allUsers.reverse()])}
                          >
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                              </svg>
                            </div>
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th className="border-black border-4 border-t-0 py-2 px-2">Edit Organizations</th>
                      <th className="border-black border-4 border-t-0 py-2 px-2">Change Email</th>
                      <th className="border-black border-4 border-t-0 py-2">Reset Password</th>
                      <th className="border-black border-4 border-t-0 py-2">Remove User</th>
                    </tr>
                  </thead>
                  {allUsers.map((user: User) => (
                    <UserRow
                      handleChangeEmail={handleChangeEmail}
                      handleSendLink={handleSendLink}
                      handleRemoveUser={handleRemoveUser}
                      user={user}
                      handleOrganizations={handleOrganizations}
                    />
                  ))}
                </table>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
