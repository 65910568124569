import { Dispatch, SetStateAction, useState } from "react";
import { BeatLoader } from "react-spinners";

import { Organization, UsersOrganization } from "../../common/interfaces";
import { ViewMemberRow } from "./ViewMemberRow";

interface Props {
  orgMembers: UsersOrganization[];
  setOrgMembers: Dispatch<SetStateAction<UsersOrganization[]>>;
  unsetAdminNotAllowed: boolean;
  setModalInvitationEmail: Dispatch<SetStateAction<boolean>>;
  setCurrentUser: Dispatch<SetStateAction<UsersOrganization | undefined>>;
  setModalRemoveUser: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => void;
  loadingInvitation: boolean;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
}

export const ViewMembers = ({
  orgMembers,
  setOrgMembers,
  unsetAdminNotAllowed,
  setModalInvitationEmail,
  setCurrentUser,
  setModalRemoveUser,
  setMessage,
  handleFetchAllOrganizations,
  loadingInvitation,
  setOrgSelected,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleSortMembers = () => {
    setOrgMembers([...orgMembers.reverse()]);
  };

  return (
    <>
      {loading || loadingInvitation ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="flex flex-col items-end">
          <button
            onClick={() => setModalInvitationEmail(true)}
            className="py-2 px-4 w-44 mt-4 border border-transparent bg-[#cca500] text-sm font-medium rounded-md text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 my-1"
          >
            Invite new User
          </button>

          <div className="w-full">
            <table className="table-auto mt-4 w-full ">
              <thead>
                <tr className="bg-[#ffce00]">
                  <th className="border-black border-4 border-t-0 py-2">Name</th>
                  <th className="border-black border-4 border-t-0 py-2">
                    <div className="flex justify-center">
                      <div>Email</div>
                      <div className="flex justify-end ml-2 cursor-pointer" onClick={handleSortMembers}>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                            <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                          </svg>
                        </div>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="border-black border-4 border-t-0 py-2">Is Admin?</th>
                  <th className="border-black border-4 border-t-0 py-2 px-2">Remove</th>
                </tr>
              </thead>
              {orgMembers.map((userOrg: UsersOrganization) => (
                <ViewMemberRow
                  userOrg={userOrg}
                  unsetAdminNotAllowed={unsetAdminNotAllowed}
                  setOrgMembers={setOrgMembers}
                  orgMembers={orgMembers}
                  setCurrentUser={setCurrentUser}
                  setModalRemoveUser={setModalRemoveUser}
                  setLoading={setLoading}
                  setMessage={setMessage}
                  handleFetchAllOrganizations={handleFetchAllOrganizations}
                  setOrgSelected={setOrgSelected}
                />
              ))}
            </table>
          </div>
        </div>
      )}
    </>
  );
};
