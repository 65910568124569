import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ErrorMessage from "../common/ErrorMessage";
import Header from "../common/Header";

import { ApiClient } from "../common/util/ApiClient";

function SignUp() {
  const { post } = ApiClient();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const search = useLocation().search;
  const inviteId = new URLSearchParams(search).get("invite") || null;

  const submitSignUp = async (event: FormEvent) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    try {
      await post("/users", { name: name, email: email, password: password, inviteId: inviteId });
      navigate("/");
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Header />
      <div className="min-h-full flex items-center justify-center pt-12 pb-3 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src="/logo_black.png" alt="sumuti logo black" />
            <h2 className="mt-6 text-center text-3xl font-sans text-white">CREATE A NEW ACCOUNT</h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={submitSignUp}>
            {error ? <ErrorMessage message="Failed to create Account." /> : null}
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="fullName"
                  name="name"
                  type="string"
                  autoComplete="name"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>

            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border-4 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                disabled={loading}
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignUp;
