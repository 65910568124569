import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Header from "../common/Header";
import FirstStage from "./stages/FirstStage";
import SecondStage from "./stages/SecondStage";
import ThirdStage from "./stages/ThirdStage";

export interface StagesProps {
  setStage: (value: number) => void;
  email: string;
  setEmail: (value: string) => void;
}

function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("confirmation_code");
  const username = searchParams.get("user_name");

  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (username && verificationCode) {
      setStage(2);
    }
  }, []);
  return (
    <>
      <Header />
      {stage === 1 && <FirstStage setStage={setStage} email={email} setEmail={setEmail} />}
      {stage === 2 && <SecondStage username={username} verificationCode={verificationCode} setStage={setStage} />}
      {stage === 3 && <ThirdStage />}
    </>
  );
}

export default ForgotPassword;
