import { createContext, ReactNode, useEffect, useReducer } from "react";

import Cookies from "js-cookie";

import { authReducer } from "./authReducer";

export interface AuthState {
  auth: boolean;
  isSumuriAdmin: boolean;
  email: string | undefined;
  access_token: string | undefined;
  id_token: string | undefined;
  refresh_token: string | undefined;
}

export const authInitialState: AuthState = {
  auth: false,
  isSumuriAdmin: false,
  email: undefined,
  access_token: undefined,
  id_token: undefined,
  refresh_token: undefined,
};

export interface AuthContextProps {
  authState: AuthState;
  signIn: (email: string, access_token: string, id_token: string, refresh_token: string) => void;
  logout: () => void;
  setSumuriAdmin: () => void;
  setEmail: (email: string) => void;
}

export const AuthContext = createContext({} as AuthContextProps);

interface Props {
  children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const [authState, dispatch] = useReducer(authReducer, authInitialState);
  const signIn = (email?: string, access_token?: string, id_token?: string, refresh_token?: string) => {
    if (email && access_token && id_token && refresh_token)
      dispatch({ type: "signIn", payload: { email, access_token, id_token, refresh_token } });
  };
  const setSumuriAdmin = () => {
    dispatch({ type: "setSumuriAdmin" });
  };

  const logout = () => {
    dispatch({ type: "logout" });
  };
  const setEmail = (email: string) => {
    Cookies.set("email", email);
    dispatch({ type: "setEmail", payload: email });
  };

  useEffect(() => {
    const login = Cookies.get("auth");
    const email = Cookies.get("email");
    const auth_token = Cookies.get("access_token");
    const refresh_token = Cookies.get("refresh_token");
    const id_token = Cookies.get("id_token");
    const isSA = Cookies.get("isSumuriAdmin");
    if (login && email) signIn(email, auth_token, id_token, refresh_token);

    if (isSA == "true") {
      setSumuriAdmin();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        signIn,
        logout,
        setSumuriAdmin,
        setEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
