import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import Cookies from "js-cookie";

import { organization } from "../Dashboard";
import IssuedLicensesTab from "./issuedLicensesTab/IssuedLicenseTab";
import LicensesTab from "./licensesTab/LicensesTab";
import { ApiClient } from "../../common/util/ApiClient";

function Licenses() {
  const { selectedOrganization } = useOutletContext<{
    selectedOrganization: organization;
    handleFetchOrganizations: () => void;
  }>();
  const { get } = ApiClient();
  const [loading, setLoading] = useState(false);
  const [licenses, setLicenses] = useState<license[]>([]);
  const [issuedLicenses, setIssuedLicenses] = useState<issuedLicense[]>([]);
  const [orgEdits, setOrgEdits] = useState<orgEditsCount>({ total: 0 });
  const [tab, setTab] = useState("licenses");
  const handleGetLicenses = async () => {
    setLoading(true);
    try {
      await handleGetEditsCount();
      const responseLicenses = await get(`licenses/${selectedOrganization.value}`);
      let responseIssuedLicenses;
      if (selectedOrganization.isAdmin) {
        responseIssuedLicenses = await get(`licenses/orgIssued/${selectedOrganization.value}`);
      } else {
        responseIssuedLicenses = await get(`licenses/issued/${Cookies.get("id")}`);
      }
      const licensesMapped = responseLicenses.data.map((license: any) => {
        return {
          id: license.id,
          quantity: license.quantity,
          expiryDate: license.expiryDate,
          purchaseDate: license.purchaseDate,
          licenseType: license.licenseType.name,
          productName: license.licenseType.product.name,
          issuedCount: license.issuedCount,
        };
      });
      const issuedLicensesMapped = responseIssuedLicenses.data.map((issuedLicense: any) => {
        const commonLicenseFields = {
          id: issuedLicense.id,
          url: issuedLicense.url,
          expiryDate: issuedLicense.expiryDate,
          installationGUId: issuedLicense.installationGUId,
          purchaseDate: issuedLicense.organizationLicense.purchaseDate,
          licenseType: issuedLicense.organizationLicense.licenseType.name,
          usbSerial: issuedLicense.usbSerial,
          modelId: issuedLicense.hardwareModelId,
          productName: issuedLicense.organizationLicense.licenseType.product.name,
        };
        if (selectedOrganization.isAdmin) {
          return {
            orgLicenseId: issuedLicense.organizationLicenseId,
            requestingUser: issuedLicense.requestingUser.email,
            ...commonLicenseFields,
          };
        } else {
          return {
            ...commonLicenseFields,
          };
        }
      });
      setLicenses(licensesMapped);
      setIssuedLicenses(issuedLicensesMapped);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleGetEditsCount = async () => {
    try {
      const responseCount = await get(`organizations/editsCount/${selectedOrganization.value}`);
      setOrgEdits(responseCount.data);
    } catch (error) {
      console.log(error);
    }
  };
  const reFetchLicenses = () => {
    handleGetLicenses();
  };
  useEffect(() => {
    if (selectedOrganization) {
      handleGetLicenses();
    }
  }, [selectedOrganization]);
  return (
    <div style={{ textAlign: "center" }}>
      <div className="min-h-full flex items-center justify-center pb-3 px-4 sm:px-6 lg:px-8 text-center">
        <div className=" grid grid-cols-2">
          <button
            type="submit"
            className="justify-center w-36 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] mr-5"
            onClick={() => setTab("licenses")}
          >
            Licenses
          </button>
          <button
            type="submit"
            className="justify-center w-36 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] ml-5"
            onClick={() => setTab("issuedLicenses")}
          >
            Issued Licenses
          </button>
        </div>
      </div>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : tab === "licenses" ? (
        <LicensesTab licenses={licenses} handleGetLicenses={handleGetLicenses} />
      ) : (
        <IssuedLicensesTab
          issuedLicenses={issuedLicenses}
          refetchLicenses={reFetchLicenses}
          isAdmin={selectedOrganization?.isAdmin}
          orgEdits={orgEdits}
        />
      )}
    </div>
  );
}

export default Licenses;

export interface license {
  id: string;
  quantity: number;
  expiryDate: Date;
  purchaseDate: Date;
  licenseType: string;
  productName: string;
  issuedCount: number;
}

export interface issuedLicense {
  id: string;
  url: string;
  installationGUId: string;
  expiryDate: Date;
  purchaseDate: Date;
  licenseType: string;
  productName: string;
  usbSerial: string;
  orgLicenseId: string;
  modelId?: string;
  requestingUser?: string;
}

export interface orgEditsCount {
  total: number;
  [key: string]: number;
}
