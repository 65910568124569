import { AuthState } from "./AuthContext";

type AuthAction =
  | { type: "signIn"; payload: { email: string; access_token: string; id_token: string; refresh_token: string } }
  | { type: "logout" }
  | { type: "setSumuriAdmin" }
  | { type: "setEmail"; payload: string };

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "signIn":
      return {
        ...state,
        auth: true,
        email: action.payload.email,
        access_token: action.payload.access_token,
        id_token: action.payload.id_token,
        refresh_token: action.payload.refresh_token,
      };

    case "logout":
      return {
        ...state,
        auth: false,
        isSumuriAdmin: false,
        email: undefined,
        access_token: undefined,
        refresh_token: undefined,
        id_token: undefined,
      };

    case "setSumuriAdmin":
      return {
        ...state,
        isSumuriAdmin: true,
      };

    case "setEmail":
      return {
        ...state,
        email: action.payload,
      };

    default:
      return state;
  }
};
