import { useEffect, useState } from "react";

import { Organization } from "./interfaces";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmDeleteOrg: (e: boolean, reconfirm: boolean) => void;
  org: Organization;
  numberOfOrgLicenses: number;
  setNumberOfOrgLicenses: React.Dispatch<React.SetStateAction<number>>;
}

const RemoveOrgModal = ({
  setShow,
  handleConfirmDeleteOrg,
  org,
  numberOfOrgLicenses,
  setNumberOfOrgLicenses,
}: Props) => {
  const [qtyUsers, setQtyUsers] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShow(false);
    const reconfirm = numberOfOrgLicenses > 0 ? true : false;
    await handleConfirmDeleteOrg(checked, reconfirm);
  };

  const handleCancel = () => {
    setShow(false);
    setNumberOfOrgLicenses(0);
  };

  useEffect(() => {
    setQtyUsers(org.usersOrganizations.length);
  }, []);

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-10 pb-4 ">
              <div className="">
                <div className="">
                  {numberOfOrgLicenses > 0 ? (
                    <div className="mt-2">
                      <p className="text-base text-gray-500">
                        Are you sure you want to close this account? There are still {numberOfOrgLicenses} active
                        licenses attached to your organization. You will need to contact support regarding license
                        reactivation
                      </p>
                      <div className="mt-2">
                        <input type="checkbox" id="notify" checked={checked} onChange={() => setChecked(!checked)} />
                        <label htmlFor="notify" className="text-base text-gray-500 ml-2">
                          Notify users by email
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <p className="text-base text-gray-500">
                        Are you sure you want to remove the organization: <b>{org.name}</b>
                        {qtyUsers && ` with ${qtyUsers} users`}?
                      </p>
                      <div className="mt-2">
                        <input type="checkbox" id="notify" checked={checked} onChange={() => setChecked(!checked)} />
                        <label htmlFor="notify" className="text-base text-gray-500 ml-2">
                          Notify users by email
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <form className="mt-1 space-y-6" onSubmit={handleSubmit}>
              <div className="bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium   sm:ml-3 sm:w-auto sm:text-sm  bg-[#cca500] text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 "
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-500"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveOrgModal;
