import { useContext, useState } from "react";
import { BeatLoader } from "react-spinners";

import { AuthContext } from "../../../context/AuthContext";
import { OrganizationContext } from "../../../context/OrganizationContext";
import ErrorMessage from "../../common/ErrorMessage";
import { useChangeEmailAndPassword } from "../../common/hooks/useChangeEmailAndPassword";
import { useLeaveOrganization } from "../../common/hooks/useLeaveOrganization";
import LeaveOrgModal from "../../common/LeaveOrgModal";
import SuccessMessage from "../../common/SuccessMessage";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePassword } from "./ChangePassword";
import { OrganizationList } from "./OrganizationList";

const Profile = () => {
  const {
    authState: { email: emailAuthenticated },
    setEmail,
  } = useContext(AuthContext);

  const {
    orgState: { userOrganizations },
    removeOrganization,
  } = useContext(OrganizationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState({
    successMessage: "",
    errorMessage: "",
  });

  const { formInput, emailFormError, passwordFormError, handleUserInput, handlePasswordSubmit, handleEmailSubmit } =
    useChangeEmailAndPassword(setEmail, emailAuthenticated, setMessage, setLoading);

  const { handleLeaveOrg, currentOrg, handleClickLeaveOrg } = useLeaveOrganization(setMessage, setLoading, setShow, removeOrganization);

  return (
    <div className="overflow-hidden">
      {show && <LeaveOrgModal setShow={setShow} handleLeaveOrg={handleLeaveOrg} orgName={currentOrg.organizationName} />}
      <div className="flex justify-center mb-5 text-center">
        <div className="w-auto">
          {message.successMessage && <SuccessMessage message={message.successMessage} />}
          {message.errorMessage && <ErrorMessage message={message.errorMessage} />}
        </div>
      </div>
      <h2 className="text-center text-3xl font-sans text-white">PROFILE</h2>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="flex flex-column overflow-hidden">
          <OrganizationList emailAuthenticated={emailAuthenticated} handleClickLeaveOrg={handleClickLeaveOrg} userOrganizations={userOrganizations} />
          <div className="items-center justify-between w-1/2 font-medium text-gray-500 border border-gray-500 rounded-xl mt-8 py-14 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 py-4 px-4">
            <div className="ml-8">
              <p className="mb-5">Change Email</p>
              <ChangeEmail
                handleEmailSubmit={handleEmailSubmit}
                formInput={formInput}
                handleUserInput={handleUserInput}
                emailFormError={emailFormError}
              />
              <ChangePassword
                handlePasswordSubmit={handlePasswordSubmit}
                formInput={formInput}
                handleUserInput={handleUserInput}
                passwordFormError={passwordFormError}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
