import { useState } from "react";

import Cookies from "js-cookie";

import GetURLModal from "../../../common/GetURLModal";

import { license } from "../Licenses";
import { ApiClient } from "../../../common/util/ApiClient";

export interface licensesTabProps {
  licenses: license[];
  handleGetLicenses: () => void;
}

function LicensesTab(props: licensesTabProps) {
  const { licenses, handleGetLicenses } = props;
  const { post } = ApiClient();
  const [show, setShow] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(0);
  const handleGetURL = async (
    index: number,
    installationName: string,
    modelId: string,
    hardwareSerial: string,
    customDuration?: number
  ) => {
    try {
      const license = licenses[index];
      const response = await post(`licenses/createURL/${license.id}`, {
        installationName,
        userId: Cookies.get("id"),
        modelId,
        hardwareSerial,
        customDuration,
      });
      const link = document.createElement("a");
      link.href = response.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShow(false);
      handleGetLicenses();
    } catch (error) {
      throw Error();
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      {show ? <GetURLModal setShow={setShow} index={selectedLicense} handleGetURL={handleGetURL} /> : null}
      <h2 className="mt-6 text-center text-3xl font-sans text-white">ORGANIZATION LICENSES</h2>
      <div className="flex justify-center">
        {licenses.length ? (
          <table className="table-auto mt-4 w-4/5">
            <thead>
              <tr className="bg-[#ffce00]">
                <th className="border-black border-b-4 py-2">Product</th>
                <th className="border-black border-4 border-t-0 py-2">License Type</th>
                <th className="border-black border-4 border-t-0 py-2">Purchase Date</th>
                <th className="border-black border-4 border-t-0 py-2">Expiry Date</th>
                <th className="border-black border-4 border-t-0 py-2">Quantity</th>
                <th className="border-black border-b-4 py-2">Issued</th>
                <th className="border-black border-b-4 py-2"></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {licenses.map((license, i) => {
                return (
                  <tr key={i} className="border-b-4 border-[#BEBEBE]">
                    <td className="py-2"> {license.productName}</td>
                    <td> {license.licenseType} </td>
                    <td>{new Date(license.purchaseDate).toLocaleDateString("en-US")}</td>
                    <td>{new Date(license.expiryDate).toLocaleDateString("en-US")}</td>
                    <td>{license.quantity}</td>
                    <td>{license.issuedCount}</td>
                    <td>
                      {license.issuedCount < license.quantity && new Date(license.expiryDate) > new Date() ? (
                        <button
                          className="justify-center py-2 px-4 my-2 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
                          onClick={() => {
                            setShow(true);
                            setSelectedLicense(i);
                          }}
                        >
                          Download
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-sans text-white">Selected organization has no licenses</h2>
        )}
      </div>
    </div>
  );
}

export default LicensesTab;
