import { FormEvent, useState } from "react";

import { ApiClient } from "../util/ApiClient";

export const useChangeEmailAndPassword = (
  setEmail: (email: string) => void,
  emailAuthenticated: string | undefined,
  setMessage: (e: { successMessage: string; errorMessage: string }) => void,
  setLoading: (e: boolean) => void
) => {
  const { post } = ApiClient();
  const [formInput, setFormInput] = useState({
    email: "",
    confirmEmail: "",
    passwordForEmail: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [emailFormError, setEmailFormError] = useState({
    email: "",
    confirmEmail: "",
  });

  const [passwordFormError, setPasswordFormError] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleUserInput = (name: string, value: string) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const handleEmailSubmit = (event: FormEvent) => {
    event.preventDefault();
    const inputError = {
      email: "",
      confirmEmail: "",
    };

    if (formInput.confirmEmail !== formInput.email) {
      setEmailFormError({
        ...inputError,
        confirmEmail: "Email and confirm email should be same",
      });
      setPasswordFormError({
        password: "",
        confirmPassword: "",
      });
      return;
    }
    setEmailFormError(inputError);
    submitEmail();
  };

  const submitEmail = async () => {
    const textMessage = {
      successMessage: "",
      errorMessage: "",
    };
    setLoading(true);
    try {
      await post(`users/updateAttribute`, {
        email: emailAuthenticated,
        password: formInput.passwordForEmail,
        field: "email",
        oldFieldValue: emailAuthenticated,
        fieldValue: formInput.email,
      });
      setEmail(formInput.email);
      setMessage({ ...textMessage, successMessage: "Please check your inbox to confirm your new email" });
      setFormInput({
        email: "",
        confirmEmail: "",
        passwordForEmail: "",
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      setMessage({ ...textMessage, errorMessage: "Failed to change email" });
    }
    setLoading(false);
  };

  const handlePasswordSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const inputError = {
      password: "",
      confirmPassword: "",
    };

    if (formInput.confirmPassword !== formInput.password) {
      setPasswordFormError({
        ...inputError,
        confirmPassword: "Password and confirm password should be same",
      });
      setEmailFormError({
        email: "",
        confirmEmail: "",
      });
      return;
    }
    setPasswordFormError(inputError);
    submitResetPassword();
  };

  const submitResetPassword = async () => {
    setLoading(true);
    const textMessage = {
      successMessage: "",
      errorMessage: "",
    };
    try {
      await post("/users/changePassword", {
        email: emailAuthenticated,
        oldPassword: formInput.oldPassword,
        password: formInput.password,
      });
      setMessage({ ...textMessage, successMessage: "Successfully changed password" });
      setFormInput({
        email: "",
        confirmEmail: "",
        passwordForEmail: "",
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.log(error);
      setMessage({ ...textMessage, errorMessage: "Failed to change password" });
    }
    setLoading(false);
  };
  return {
    formInput,
    setMessage,
    setLoading,
    emailFormError,
    passwordFormError,
    handleUserInput,
    handlePasswordSubmit,
    handleEmailSubmit,
  };
};
