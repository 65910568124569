import { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";

import Cookies from "js-cookie";

import { OrganizationContext } from "../../context/OrganizationContext";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

import NewUserView from "./organizations/newUserView/NewUserView";
import { ApiClient } from "../common/util/ApiClient";

function Dashboard() {
  const { addOrganizations } = useContext(OrganizationContext);
  const { get } = ApiClient();
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<organization | undefined | null>();

  const handleFetchOrganizations = async () => {
    setSelectedOrganization(null);
    setLoading(true);
    try {
      const response = await get(`organizations/user/${Cookies.get("id")}`);

      addOrganizations(response.data);
      const options = response.data.map((org: any) => {
        return { value: org.organization.id, label: org.organization.name, isAdmin: org.isAdmin };
      });
      if (options.length == 1) {
        setSelectedOrganization(options[0]);
      }
      setOrganizations(options);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchOrganizations();
  }, []);

  return (
    <div>
      <div className="fixed w-full z-50">
        <Header />
        <Sidebar />
      </div>
      <div className="pt-20">
        <div>
          {loading ? (
            <div className="text-center mt-10">
              <BeatLoader color="yellow" />
            </div>
          ) : (
            <>
              <div>
                <div className="m-8 float-right">
                  <Select
                    className="inline-block mr-8 w-52"
                    value={selectedOrganization}
                    options={organizations}
                    onChange={(e) => setSelectedOrganization(e)}
                  />
                  <Link to="newOrganization">
                    <button
                      type="submit"
                      className="inline-block justify-center w-348 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] mr-5"
                    >
                      New Organization
                    </button>
                  </Link>
                </div>
                <div className="clear-right ml-60">
                  <Outlet context={{ selectedOrganization, handleFetchOrganizations }} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

export interface organization {
  value: string;
  label: string;
  isAdmin: boolean;
}
