const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export { emailRegex };

export const matchRegex = (str: string, regex: string) => {
  const re = new RegExp(regex);
  if (re.test(str)) {
    return true;
  } else {
    return false;
  }
};
