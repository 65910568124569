import { Dispatch, SetStateAction } from "react";

import { Organization, UsersOrganization } from "../../common/interfaces";
import { OrgAdministrationTable } from "./OrgAdministrationTable";
import { OrganizationProfileComponent } from "./OrganizationProfileMain";
import { ShowMembers } from "./ShowMembers";

interface OrgAdministrationMainProps {
  showMembers: boolean;
  orgMembers: UsersOrganization[];
  setOrgMembers: Dispatch<SetStateAction<UsersOrganization[]>>;
  unsetAdminNotAllowed: boolean;
  setModalInvitationEmail: Dispatch<SetStateAction<boolean>>;
  setCurrentUser: Dispatch<SetStateAction<UsersOrganization | undefined>>;
  setModalRemoveUser: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<{ successMessage: string; errorMessage: string }>>;
  handleFetchAllOrganizations: () => Promise<void>;
  loadingInvitation: boolean;
  handleGoBackShowMembers: () => void;
  showEditOrg: boolean;
  orgSelected: Organization | undefined;
  handleGoBackEditOrg: () => void;
  setAllOrgs: Dispatch<SetStateAction<Organization[]>>;
  allOrgs: Organization[];
  setShowMembers: Dispatch<SetStateAction<boolean>>;
  setShowEditOrg: Dispatch<SetStateAction<boolean>>;
  setOrgSelected: Dispatch<SetStateAction<Organization | undefined>>;
  setTitle: Dispatch<SetStateAction<string>>;
  setRemoveOrgModal: Dispatch<SetStateAction<boolean>>;
}
export const OrgAdministrationMain = ({
  orgSelected,
  showEditOrg,
  showMembers,
  orgMembers,
  setOrgMembers,
  unsetAdminNotAllowed,
  setModalInvitationEmail,
  setCurrentUser,
  setModalRemoveUser,
  setMessage,
  handleFetchAllOrganizations,
  loadingInvitation,
  handleGoBackShowMembers,
  handleGoBackEditOrg,
  setAllOrgs,
  allOrgs,
  setShowMembers,
  setShowEditOrg,
  setOrgSelected,
  setTitle,
  setRemoveOrgModal,
}: OrgAdministrationMainProps) => {
  if (showMembers) {
    return (
      <ShowMembers
        orgMembers={orgMembers}
        setOrgMembers={setOrgMembers}
        unsetAdminNotAllowed={unsetAdminNotAllowed}
        setModalInvitationEmail={setModalInvitationEmail}
        setCurrentUser={setCurrentUser}
        setModalRemoveUser={setModalRemoveUser}
        setMessage={setMessage}
        handleFetchAllOrganizations={handleFetchAllOrganizations}
        loadingInvitation={loadingInvitation}
        handleGoBackShowMembers={handleGoBackShowMembers}
        setOrgSelected={setOrgSelected}
      />
    );
  }
  if (showEditOrg && orgSelected) {
    return (
      <OrganizationProfileComponent
        setOrgSelected={setOrgSelected}
        orgSelected={orgSelected}
        setMessage={setMessage}
        handleFetchAllOrganizations={handleFetchAllOrganizations}
        handleGoBackEditOrg={handleGoBackEditOrg}
      />
    );
  }
  return (
    <OrgAdministrationTable
      setAllOrgs={setAllOrgs}
      allOrgs={allOrgs || []}
      setShowMembers={setShowMembers}
      setOrgMembers={setOrgMembers}
      setShowEditOrg={setShowEditOrg}
      setOrgSelected={setOrgSelected}
      setTitle={setTitle}
      setRemoveOrgModal={setRemoveOrgModal}
    />
  );
};
