import { useEffect, useState } from "react";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";

import Cookies from "js-cookie";

import DeleteIssuedLicenseModal from "../../common/DeleteIssuedLicenseModal";
import EditIssuedLicenseModal from "../../common/EditIssuedLicenseModal";

import { organization } from "../../dashboard/Dashboard";
import { issuedLicense } from "../../dashboard/licenses/Licenses";

import "react-datepicker/dist/react-datepicker.css";

import { ApiClient } from "../../common/util/ApiClient";

function EditIssuedLicenses() {
  const { get, patch, del } = ApiClient();
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<organization | undefined | null>();
  const [disabled, setDisabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIssuedLicense, setSelectedIssuedLicense] = useState(0);
  const [issuedLicenses, setIssuedLicenses] = useState<issuedLicense[]>([]);

  const handleGetIssuedLicenses = async () => {
    setLoading(true);
    try {
      const responseIssuedLicenses = await get(`licenses/orgIssued/${selectedOrganization?.value}`);
      const issuedLicensesMapped = responseIssuedLicenses.data.map((issuedLicense: any) => {
        return {
          id: issuedLicense.id,
          url: issuedLicense.url,
          expiryDate: issuedLicense.expiryDate,
          installationGUId: issuedLicense.installationGUId,
          purchaseDate: issuedLicense.organizationLicense.purchaseDate,
          licenseType: issuedLicense.organizationLicense.licenseType.name,
          modelId: issuedLicense.hardwareModelId,
          usbSerial: issuedLicense.usbSerial,
          orgLicenseId: issuedLicense.organizationLicenseId,
          productName: issuedLicense.organizationLicense.licenseType.product.name,
          requestingUser: issuedLicense.requestingUser.email,
        };
      });
      setIssuedLicenses(issuedLicensesMapped);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleFetchOrganizations = async () => {
    setSelectedOrganization(null);
    setLoading(true);

    try {
      const responseOrganizations = await get("organizations/all");
      const optionsOrganizations = responseOrganizations.data.map((org: any) => {
        return { value: org.id, label: org.name };
      });
      setOrganizations(optionsOrganizations);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRemove = async (index: number, editReason: string) => {
    try {
      setDisabled(true);
      const issuedLicense = issuedLicenses[index];
      await del(`/licenses/issuedLicense/${issuedLicense.id}`, {
        data: { userId: Cookies.get("id"), editReason },
      });
      setShowDeleteModal(false);
      handleGetIssuedLicenses();
    } catch (error) {
      throw Error();
    }
    setDisabled(false);
  };

  const handleEdit = async (
    index: number,
    installationName: string,
    modelId: string,
    hardwareSerial: string,
    editReason: string,
    customDuration?: number
  ) => {
    try {
      setDisabled(true);

      const issuedLicense = issuedLicenses[index];
      await patch(`/licenses/editIssued/${issuedLicense.id}`, {
        issuedLicenseId: issuedLicense.id,
        installationName,
        userId: Cookies.get("id"),
        modelId,
        hardwareSerial,
        editReason,
        customDuration,
      });
      setShowEditModal(false);
      handleGetIssuedLicenses();
    } catch (error) {
      throw Error();
    }
    setDisabled(false);
  };

  useEffect(() => {
    handleFetchOrganizations();
  }, []);

  useEffect(() => {
    selectedOrganization && handleGetIssuedLicenses();
  }, [selectedOrganization]);
  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="mt-12 text-center text-3xl font-sans text-white">EDIT ISSUED LICENSES</h2>
      {showDeleteModal ? (
        <DeleteIssuedLicenseModal
          setShow={setShowDeleteModal}
          index={selectedIssuedLicense}
          handleRemove={handleRemove}
        />
      ) : null}
      {showEditModal ? (
        <EditIssuedLicenseModal
          setShow={setShowEditModal}
          index={selectedIssuedLicense}
          handleEdit={handleEdit}
          issuedLicense={issuedLicenses[selectedIssuedLicense]}
        />
      ) : null}
      <div className="min-h-full pt-6 pb-3 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 flex mx-auto">
          <Select
            className="inline-block w-full"
            options={organizations}
            value={selectedOrganization}
            onChange={(e) => setSelectedOrganization(e)}
            placeholder="Select Organization"
            isDisabled={disabled}
          />
        </div>
        <div className="flex items-center justify-center my-4">
          {loading ? (
            <div className="text-center mt-10">
              <BeatLoader color="yellow" />
            </div>
          ) : issuedLicenses.length ? (
            <table className="table-auto mt-4 w-4/5">
              <thead>
                <tr className="bg-[#ffce00]">
                  <th className="border-black border-b-4 py-2">Product</th>
                  <th className="border-black border-4 border-t-0 py-2">License Type</th>
                  <th className="border-black border-4 border-t-0 py-2">Installation Name</th>
                  <th className="border-black border-4 border-t-0 py-2">Expiry Date</th>
                  <th className="border-black border-b-4 py-2">User Email</th>
                  <th className="border-black border-b-4 "></th>
                  <th className="border-black border-b-4 "></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {issuedLicenses.map((issuedLicense, i) => {
                  return (
                    <tr key={i} className="border-b-4 border-[#BEBEBE]">
                      <td className="py-2"> {issuedLicense.productName}</td>
                      <td> {issuedLicense.licenseType} </td>
                      <td> {issuedLicense.installationGUId} </td>
                      <td>{new Date(issuedLicense.expiryDate).toLocaleDateString("en-US")}</td>
                      <td>{issuedLicense.requestingUser}</td>
                      <td>
                        <button
                          onClick={() => {
                            setShowEditModal(true);
                            setSelectedIssuedLicense(i);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="green"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setShowDeleteModal(true);
                            setSelectedIssuedLicense(i);
                          }}
                          className="text-green"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="red"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h2 className="mt-6 text-center text-3xl font-sans text-white">
              Selected organization has no issued licenses
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditIssuedLicenses;
