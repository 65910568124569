import { useContext, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

import Cookies from "js-cookie";

import { OrganizationContext } from "../../../context/OrganizationContext";
import ErrorMessage from "../../common/ErrorMessage";
import { Organization, OrganizationTitle, UsersOrganization } from "../../common/interfaces";
import RemoveOrgModal from "../../common/RemoveOrgModal";
import RemoveUserModal from "../../common/RemoveUserModal";
import SendInvitationModal from "../../common/SendInvitationModal";
import SuccessMessage from "../../common/SuccessMessage";

import { OrgAdministrationMain } from "./OrgAdministrationMain";
import { ApiClient } from "../../common/util/ApiClient";

export const OrgAdministration = () => {
  const {
    orgState: { allOrganizations },
    addAllOrganizations,
  } = useContext(OrganizationContext);

  const { get, post, del } = ApiClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalInvitationEmail, setModalInvitationEmail] = useState<boolean>(false);
  const [allOrgs, setAllOrgs] = useState<Organization[]>([]);
  const [showMembers, setShowMembers] = useState<boolean>(false);
  const [orgMembers, setOrgMembers] = useState<UsersOrganization[]>([]);
  const [showEditOrg, setShowEditOrg] = useState<boolean>(false);
  const [orgSelected, setOrgSelected] = useState<Organization>();
  const [title, setTitle] = useState<string>(OrganizationTitle.ORG_ADM);
  const [unsetAdminNotAllowed, setUnsetAdminNotAllowed] = useState<boolean>(false);
  const [invitationEmail, setInvitationEmail] = useState<string>("");
  const [modalRemoveUser, setModalRemoveUser] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UsersOrganization>();
  const [removeOrgModal, setRemoveOrgModal] = useState<boolean>(false);
  const [loadingInvitation, setLoadingInvitation] = useState<boolean>(false);
  const [numberOfOrgLicenses, setNumberOfOrgLicenses] = useState<number>(0);
  const [message, setMessage] = useState({
    successMessage: "",
    errorMessage: "",
  });

  const handleGoBackEditOrg = () => {
    setShowEditOrg(false);
    setTitle(OrganizationTitle.ORG_ADM);
  };

  const handleGoBackShowMembers = () => {
    setShowMembers(false);
    setTitle(OrganizationTitle.ORG_ADM);
  };

  useEffect(() => {
    setAllOrgs(allOrganizations?.sort((a: Organization, b: Organization) => a.name.localeCompare(b.name)));
  }, [allOrganizations]);

  const handleFetchAllOrganizations = async () => {
    try {
      const response = await get("organizations/all");
      await addAllOrganizations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (orgMembers) {
      const adminMembers = orgMembers.filter((usersOrg: UsersOrganization) => usersOrg.isAdmin);
      if (adminMembers.length === 1) {
        setUnsetAdminNotAllowed(true);
      } else {
        setUnsetAdminNotAllowed(false);
      }
    }
  }, [orgMembers]);

  const handleConfirmDeleteUser = async (notification = false) => {
    const message = {
      successMessage: "",
      errorMessage: "",
    };

    setLoading(true);

    try {
      const response = await post(`/organizations/members/${currentUser?.id}`, {
        notification,
        adminUserId: Cookies.get("id"),
        orgId: orgSelected?.id,
      });
      setMessage({ ...message, successMessage: "User removed successfuly" });
      setOrgMembers([
        ...orgMembers.filter((userOrganization: UsersOrganization) => userOrganization.id !== currentUser?.id),
      ]);
      handleFetchAllOrganizations();
      if (response.data === "Success removed user and organization") {
        setShowMembers(false);
      }
    } catch (error) {
      setMessage({ ...message, successMessage: "User was not removed, please try again" });
      console.log(error);
      throw Error();
    }
    setLoading(false);
  };

  const handleSendInvitation = async () => {
    const message = {
      successMessage: "",
      errorMessage: "",
    };
    setLoadingInvitation(true);

    try {
      await post(`/organizations/invite/${orgSelected?.id}`, {
        email: invitationEmail,
        userId: Cookies.get("id"),
      });
      setMessage({ ...message, successMessage: "Invitation sent successfully" });
      setInvitationEmail("");
    } catch (error) {
      console.log(error);
      setMessage({ ...message, successMessage: "Invitation was not sent successfully" });
    }

    setLoadingInvitation(false);
  };

  const handleConfirmDeleteOrg = async (notification: boolean, reconfirm = false) => {
    const message = {
      successMessage: "",
      errorMessage: "",
    };
    setLoading(true);
    try {
      const orgHaveLicenses = await get(`licenses/${orgSelected?.id}`);

      if (orgHaveLicenses.data.length > 0 && !reconfirm) {
        setNumberOfOrgLicenses(orgHaveLicenses.data.length);
        setLoading(false);
        setRemoveOrgModal(true);
        return;
      } else {
        setNumberOfOrgLicenses(0);
        if (notification) {
          await post(`/organizations/deleteOrg/notify`, {
            id: orgSelected?.id,
            userId: Cookies.get("id"),
          });
        } else {
          await post("/organizations/deleteOrg", { id: orgSelected?.id, userId: Cookies.get("id") });
        }
        setMessage({ ...message, successMessage: "Organization removed successfully" });
        handleFetchAllOrganizations();
      }
    } catch (error) {
      setMessage({ ...message, successMessage: "Organization was not removed, please try again" });
      console.log(error);
      throw Error();
    }
    setLoading(false);
  };

  return (
    <div className="min-h-full flex items-center justify-center pb-3 px-4 sm:px-6 lg:px-8">
      {removeOrgModal && orgSelected && (
        <RemoveOrgModal
          setShow={setRemoveOrgModal}
          handleConfirmDeleteOrg={handleConfirmDeleteOrg}
          org={orgSelected}
          numberOfOrgLicenses={numberOfOrgLicenses}
          setNumberOfOrgLicenses={setNumberOfOrgLicenses}
        />
      )}
      {modalRemoveUser && currentUser && (
        <RemoveUserModal
          setShow={setModalRemoveUser}
          handleConfirmDeleteUser={handleConfirmDeleteUser}
          user={currentUser}
          orgSelected={orgSelected}
          orgAdmin
        />
      )}

      {modalInvitationEmail && orgSelected && (
        <SendInvitationModal
          setShow={setModalInvitationEmail}
          setInvitationEmail={setInvitationEmail}
          invitationEmail={invitationEmail}
          org={orgSelected}
          handleSendInvitation={handleSendInvitation}
        />
      )}

      <div className="w-9/12 space-y-8">
        <div>
          <div className="flex justify-center mt-8 text-center">
            <div className="w-100">
              {message.successMessage && <SuccessMessage message={message.successMessage} />}
              {message.errorMessage && <ErrorMessage message={message.errorMessage} />}
            </div>
          </div>
          <h2 className="my-12 text-center text-3xl font-sans text-white">{title}</h2>
        </div>
        <div className={`w-full flex items-center justify-center mb-24 ${showMembers && "flex-col"}`}>
          {loading ? (
            <div className="text-center mt-10">
              <BeatLoader color="yellow" />
            </div>
          ) : (
            <OrgAdministrationMain
              showMembers={showMembers}
              orgMembers={orgMembers}
              setOrgMembers={setOrgMembers}
              unsetAdminNotAllowed={unsetAdminNotAllowed}
              setModalInvitationEmail={setModalInvitationEmail}
              setCurrentUser={setCurrentUser}
              setModalRemoveUser={setModalRemoveUser}
              setMessage={setMessage}
              handleFetchAllOrganizations={handleFetchAllOrganizations}
              loadingInvitation={loadingInvitation}
              handleGoBackShowMembers={handleGoBackShowMembers}
              showEditOrg={showEditOrg}
              orgSelected={orgSelected}
              handleGoBackEditOrg={handleGoBackEditOrg}
              setAllOrgs={setAllOrgs}
              allOrgs={allOrgs}
              setShowMembers={setShowMembers}
              setShowEditOrg={setShowEditOrg}
              setOrgSelected={setOrgSelected}
              setTitle={setTitle}
              setRemoveOrgModal={setRemoveOrgModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
