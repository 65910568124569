import { useState } from "react"
import { Link, NavLink, useMatch } from "react-router-dom"


function Sidebar() {
    const [selected, setSelected] = useState(1)

    return (
        <div className="w-60 h-full shadow-md bg-[#24272d] px-1 fixed">
            <ul className="relative">
                <li className="relative">
                    <NavLink to="/dashboard/" className={`flex items-center text-sm py-4 px-6 h-12 ${selected == 1 ? "text-gray-900 bg-gray-100" : "text-white"} text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out `} onClick={()=> setSelected(1)}>
                    <svg aria-hidden="true" className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                    </svg>
                    <span className="ml-3">Organizations</span>
                    </NavLink>
                </li>
                <li className="relative">
                    <NavLink to="/dashboard/licenses" className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${selected == 2 ? "text-gray-900 bg-gray-100" : "text-white"} text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`} onClick={()=> setSelected(2)}>
                    <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
                    <span className="ml-3">Licenses</span>
                    </NavLink>
                </li>
                <li className="relative">
                    <NavLink to="/dashboard/profile" className={`flex items-center text-sm py-4 px-6 h-12 overflow-hidden ${selected == 3 ? "text-gray-900 bg-gray-100" : "text-white"} text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out`} onClick={()=> setSelected(3)}>
                    <svg aria-hidden="true" className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                    <span className="ml-3">Profile</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar