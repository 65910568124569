import { useEffect, useState } from "react";

import { Organization, UsersOrganization } from "./interfaces";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmDeleteUser: (e?: boolean) => void;
  user: any;
  orgAdmin?: boolean;
  orgSelected?: Organization;
}

const RemoveUserModal = ({ setShow, orgSelected, handleConfirmDeleteUser, user, orgAdmin }: Props) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShow(false);

    if (orgAdmin) {
      await handleConfirmDeleteUser(checked);
    } else {
      await handleConfirmDeleteUser();
    }
  };

  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfAdmins, setNumberOfAdmins] = useState<number>(0);
  const [notAllowedToRemove, setNotAllowedToRemove] = useState<boolean>(false);

  useEffect(() => {
    setNumberOfUsers(orgSelected?.usersOrganizations?.length || 0);
    setNumberOfAdmins(orgSelected?.usersOrganizations?.filter((user: UsersOrganization) => user.isAdmin).length || 0);

    if (numberOfUsers && numberOfUsers > 1 && numberOfAdmins === 1 && user.isAdmin) {
      setNotAllowedToRemove(true);
    } else {
      setNotAllowedToRemove(false);
    }
  }, [numberOfUsers]);

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-center overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-10 pb-4 ">
              <div className="">
                <div className="">
                  <div className="mt-2">
                    {orgAdmin && notAllowedToRemove ? (
                      <p className="text-base text-gray-500">
                        Unable to leave organization because there are other users, but no admin user.
                      </p>
                    ) : orgAdmin && numberOfUsers === 1 ? (
                      <p className="text-base text-gray-500">
                        You are removing the last user of <b>{orgSelected?.name}</b> organization. The organization will
                        also be removed
                      </p>
                    ) : orgAdmin ? (
                      <p className="text-base text-gray-500">
                        Are you sure you want to remove the user: <b>{user.user.name} </b>from the organization?
                      </p>
                    ) : (
                      <p className="text-base text-gray-500">
                        Are you sure you want to delete the user: <b>{user.name}?</b>
                      </p>
                    )}

                    {orgAdmin && !notAllowedToRemove && (
                      <div className="mt-2">
                        <input type="checkbox" id="notify" checked={checked} onChange={() => setChecked(!checked)} />
                        <label htmlFor="notify" className="text-base text-gray-500 ml-2">
                          Notify user by email
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <form className="mt-1 space-y-6" onSubmit={handleSubmit}>
              <div className="bg-gray-50 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                {!notAllowedToRemove && (
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium   sm:ml-3 sm:w-auto sm:text-sm  bg-[#cca500] text-black hover:bg-[#ffce00] disabled:bg-gray-400 disabled:hover:bg-gray-400 "
                  >
                    Confirm
                  </button>
                )}
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:bg-gray-500"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveUserModal;
