import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Select from "react-select";
import BeatLoader from "react-spinners/BeatLoader";

import ErrorMessage from "../../common/ErrorMessage";
import SuccessMessage from "../../common/SuccessMessage";

import { organization } from "../../dashboard/Dashboard";

import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../../context/AuthContext";
import { ApiClient } from "../../common/util/ApiClient";

function AddLicenseToOrganization() {
  const { get, post } = ApiClient();
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<organization | undefined | null>();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedLicense, setSelectedLicense] = useState<any>();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [quantity, setQuantity] = useState(0);

  const handleFetchOrganizationsAndProducts = async () => {
    setSelectedOrganization(null);
    setLoading(true);
    try {
      const responseOrganizations = await get("organizations/all");
      const optionsOrganizations = responseOrganizations.data.map((org: any) => {
        return { value: org.id, label: org.name };
      });
      setOrganizations(optionsOrganizations);
      const responseProducts = await get("licenses/products");
      const optionsProducts = responseProducts.data.map((prod: any) => {
        return {
          value: prod.id,
          label: prod.name,
          licenseTypes: prod.licenseTypes.map((license: any) => {
            return { value: license.id, label: license.name };
          }),
        };
      });
      setProducts(optionsProducts);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const submitAddLicenseToOrganization = async (e: any) => {
    e.preventDefault();
    setSuccess(false);
    try {
      const body = {
        licenseTypeId: selectedLicense?.value,
        quantity: quantity,
        organizationId: selectedOrganization?.value,
        startDate: startDate,
        endDate: endDate,
      };
      await post("/licenses/LicenseToOrg", body);
      setSuccess(true);
      setSelectedLicense(null);
      setSelectedOrganization(null);
      setSelectedProduct(null);
      setQuantity(0);
      setStartDate(null);
      setEndDate(null);
    } catch {
      setError(true);
    }
    setDisabled(false);
  };

  useEffect(() => {
    handleFetchOrganizationsAndProducts();
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <h2 className="mt-12 text-center text-3xl font-sans text-white">ADD LICENSE TO ORGANIZATION</h2>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : (
        <div className="min-h-full flex items-center justify-center pt-6 pb-3 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <form
              className="mt-8 space-y-6"
              onSubmit={(e: any) => {
                setDisabled(true);
                submitAddLicenseToOrganization(e);
              }}
            >
              {error ? <ErrorMessage message="Failed to add License to organization" /> : null}
              {success ? <SuccessMessage message="Successfully added License to Organization" /> : null}
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <Select
                    className="inline-block mr-8 w-full"
                    options={organizations}
                    value={selectedOrganization}
                    onChange={(e) => setSelectedOrganization(e)}
                    placeholder="Select Organization"
                    isDisabled={disabled}
                  />
                </div>
              </div>

              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <div>
                    <Select
                      className="inline-block mr-8 w-full"
                      options={products}
                      value={selectedProduct}
                      onChange={(e) => setSelectedProduct(e)}
                      placeholder="Select Product"
                      isDisabled={disabled}
                    />
                  </div>
                </div>
              </div>
              {selectedOrganization && selectedProduct ? (
                <>
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <div>
                        <Select
                          className="inline-block mr-8 mb-4 w-full"
                          options={selectedProduct.licenseTypes || []}
                          value={selectedLicense}
                          onChange={(e) => setSelectedLicense(e)}
                          placeholder="Select License"
                          isDisabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: any) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      className="w-full mb-6 text-center border-2 py-1 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                      placeholderText="Select Start Date"
                      disabled={disabled}
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date: any) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      className="w-full mb-4 text-center border-2 py-1 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                      placeholderText="Select End Date"
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <div>
                      <input
                        id="quantity"
                        name="quantity"
                        type="number"
                        autoComplete="quantity"
                        required
                        className="appearance-none text-center rounded-none relative block w-full px-3 py-1 border-2 border-white placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#3b82f6] focus:border-[#3b82f6] focus:z-10 sm:text-md disabled:bg-white"
                        placeholder="Quantity"
                        value={quantity || ""}
                        onChange={(e) => setQuantity(Number(e.target.value))}
                        disabled={disabled}
                        min="0"
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] disabled:bg-gray-500"
                  disabled={
                    disabled ||
                    !selectedProduct ||
                    !selectedOrganization ||
                    !selectedLicense ||
                    !startDate ||
                    !endDate ||
                    !quantity
                  }
                >
                  Add License to Organization
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddLicenseToOrganization;
