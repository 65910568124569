import { Organization, UsersOrganization } from "../components/common/interfaces";
import { OrganizationState } from "./OrganizationContext";

type AuthAction =
  | { type: "addOrganizations"; payload: UsersOrganization[] }
  | { type: "addAllOrganizations"; payload: Organization[] }
  | { type: "removeOrganization"; payload: string };

export const organizationReducer = (state: OrganizationState, action: AuthAction): OrganizationState => {
  switch (action.type) {
    case "addOrganizations":
      return {
        ...state,
        userOrganizations: action.payload,
      };

    case "addAllOrganizations":
      return {
        ...state,
        allOrganizations: action.payload,
      };

    case "removeOrganization":
      return {
        ...state,
        userOrganizations: state.userOrganizations.filter((org) => org.organization.id !== action.payload),
      };

    default:
      return state;
  }
};
