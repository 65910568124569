import { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import { organization } from "../Dashboard";
import InvitesTab from "./invitesTab/InvitesTab";
import MembersTab from "./membersTab/MembersTab";
import { ApiClient } from "../../common/util/ApiClient";
import NewUserView from "./newUserView/NewUserView";
import { OrganizationContext } from "../../../context/OrganizationContext";

function Organizations() {
  const { get } = ApiClient();
  const {
    orgState: { userOrganizations },
  } = useContext(OrganizationContext);
  const { selectedOrganization, handleFetchOrganizations } = useOutletContext<{
    selectedOrganization: organization;
    handleFetchOrganizations: () => void;
  }>();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<user[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<invitedUser[]>([]);
  const [newUsers, setNewUsers] = useState<newUser[]>([]);
  const [tab, setTab] = useState("members");
  const handleGetMembers = async () => {
    setLoading(true);
    try {
      const response = await get(`organizations/members/${selectedOrganization.value}`);
      const responseUsers: user[] = response.data.users.map((user: any) => {
        return {
          email: user.user.email,
          id: user.user.id,
          name: user.user.name,
          isAdmin: user.isAdmin,
          userOrgId: user.id,
        };
      });
      const responseNewUsers: newUser[] = response.data.newUsers.map((newUser: any) => {
        return {
          email: newUser.email,
          invitingUserName: newUser.user.name,
          createdAt: newUser.created_at,
        };
      });
      const responseInvitedUsers: invitedUser[] = response.data.invitedUsers.map((invitedUser: any) => {
        return {
          email: invitedUser.user.email,
          inviteId: invitedUser.id,
          name: invitedUser.user.name,
          invitingUserName: invitedUser.invitingUser.name,
          createdAt: invitedUser.created_at,
        };
      });
      setUsers(responseUsers);
      setInvitedUsers(responseInvitedUsers);
      setNewUsers(responseNewUsers);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedOrganization) {
      handleGetMembers();
    }
  }, [selectedOrganization]);

  if (userOrganizations.length === 0) {
    return (
      <div>
        <NewUserView handleFetchOrganizations={handleFetchOrganizations} />{" "}
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div className="min-h-full flex items-center justify-center pb-3 px-4 sm:px-6 lg:px-8 text-center">
        <div className=" grid grid-cols-2">
          <button
            type="submit"
            className="justify-center w-36 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] mr-5"
            onClick={() => setTab("members")}
          >
            Members
          </button>
          <button
            type="submit"
            className="justify-center w-36 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00] ml-5"
            onClick={() => setTab("invites")}
          >
            Invites
          </button>
        </div>
      </div>
      {loading ? (
        <div className="text-center mt-10">
          <BeatLoader color="yellow" />
        </div>
      ) : tab == "members" ? (
        <MembersTab users={users} isAdmin={selectedOrganization?.isAdmin} />
      ) : (
        <InvitesTab
          organization={selectedOrganization}
          handleGetMembers={handleGetMembers}
          invitedUsers={invitedUsers}
          newUsers={newUsers}
        />
      )}
    </div>
  );
}

export default Organizations;

export interface user {
  email: string;
  id: string;
  name: string;
  isAdmin: boolean;
  userOrgId: string;
}

export interface invitedUser {
  email: string;
  name: string;
  invitingUserName: string;
  createdAt: Date;
  inviteId: string;
}

export interface newUser {
  email: string;
  createdAt: Date;
  invitingUserName: string;
}
