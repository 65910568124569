import { Link } from "react-router-dom";

import Header from "./common/Header";
import Login from "./login/Login";

function Home() {
  return (
    <div style={{ textAlign: "center" }}>
      <Header />
      <Login />
      <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div className="pb-8">
            <h3 className="mx-auto mt-6 text-center text-3xl font-sans text-white">OR</h3>
          </div>
          <div>
            <Link to={"/signUp"}>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-[#cca500] hover:bg-[#ffce00]"
              >
                Register
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
