import { useState } from "react";

interface deleteIssuedLicenseModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  handleRemove: (index: number, editReason: string) => Promise<void>;
}

function DeleteIssuedLicenseModal(props: deleteIssuedLicenseModalProps) {
  const { setShow, index, handleRemove } = props;
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [editReason, setEditReason] = useState("");


  const handleSubmit = async () => {
    setError(false);
    setDisabled(true);
    try {
      await handleRemove(index, editReason);
    } catch {
      setError(true);
    }
    setDisabled(false);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    Delete Issued License
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete this license? This action
                      cannot be undone.
                    </p>
                  </div>
                  <div className="rounded-md shadow-sm -space-y-px text-center mt-4">
                    <div>
                      <input
                        id="EditReason"
                        name="EditReason"
                        type="string"
                        autoComplete="EditReason"
                        required
                        className="appearance-none rounded-none w-full px-3 py-2 border-2 border-black placeholder-gray-500 text-gray-900 rounded-b-md rounded-t-md focus:outline-none focus:ring-[#ffce00] focus:border-[#ffce00] focus:z-10 sm:text-sm disabled:border-gray-400 disabled:bg-gray-400"
                        placeholder="Edit Reason..."
                        value={editReason}
                        onChange={(e) => setEditReason(e.target.value)}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  {error ? (
                    <div className="mt-2">
                      <p className="text-sm text-red-500">
                        Failed to remove license
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium disabled:bg-gray-400 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={!editReason.length || disabled}
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Delete
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                disabled={disabled}
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteIssuedLicenseModal;
