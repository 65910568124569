import { useState } from "react";

import { Organization } from "../interfaces";

import { ApiClient } from "../util/ApiClient";

export const useLeaveOrganization = (
  setMessage: (e: { successMessage: string; errorMessage: string }) => void,
  setLoading: (e: boolean) => void,
  setShow: (e: boolean) => void,
  removeOrganization?: (id: string) => void,
  removeAdminOrganization?: () => void
) => {
  const [currentOrg, setCurrentOrg] = useState({
    userIdOrganization: "",
    organizationId: "",
    organizationName: "",
  });
  const { del } = ApiClient();

  const handleClickLeaveOrg = (userIdOrganization: string, { id, name }: Organization) => {
    setShow(true);
    setCurrentOrg({ userIdOrganization, organizationId: id, organizationName: name });
  };

  const handleLeaveOrg = async () => {
    setShow(false);
    setLoading(true);
    const textMessage = {
      successMessage: "",
      errorMessage: "",
    };
    try {
      await del(`organizations/members/${currentOrg.userIdOrganization}`);
      setMessage({ ...textMessage, successMessage: "Leave from organization successfully" });
      if (removeOrganization) removeOrganization(currentOrg.organizationId);
      if (removeAdminOrganization) removeAdminOrganization();
    } catch (error) {
      console.log(error);

      setMessage({ ...textMessage, errorMessage: "Failed to Leave from organization successfully" });
      throw Error();
    }
    setCurrentOrg({
      userIdOrganization: "",
      organizationId: "",
      organizationName: "",
    });
    setLoading(false);
  };
  return { handleClickLeaveOrg, handleLeaveOrg, currentOrg };
};
